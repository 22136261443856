import {useMutation, useQuery} from "react-query";
import {normalFetch} from "../fetch-api/get";
import {postApiWithToken} from "../fetch-api/post";
import {useMainContext} from "../../contexts/main";
import {useQueryContext} from "../../contexts/query";


const useAvailableCoin = () => {

    return useQuery(
        'fetch-available-coins', () => normalFetch(null, "settings/coins"),
        {
            select: (res) => (res?.data?.data),
            cacheTime: 60 * 60 * 1000,
            staleTime: 60 * 60 * 1000,
            refetchOnWindowFocus: false
        }
    )
}


const useWageCalculator = () => {
    const { profile: { token } } = useMainContext()

    return useMutation(
        'wages-calculator', (data) => postApiWithToken(data, token, "settings/wages/calculate"),
    )
}

const useWithdrawMutation = ({ callback } = {}) => {
    const {
        profile: { token },
    } = useMainContext()
    return useMutation('withdraw-coin', (data) => postApiWithToken(data, token, 'coining/withdraw'), {
        onSuccess: () => {
            callback?.()
        },
    })
}

const useBankWithdrawMutation = ({ callback } = {}) => {
    const {
        profile: { token },
    } = useMainContext()

    return useMutation('withdraw-bank', (data) => postApiWithToken(data, token, 'banking/withdraw'), {
        onSuccess: () => {
            callback?.()
        },
    })
}


const useCreateOtcOrder = (onClose) => {
    const { profile: { token }, setLoading } = useMainContext()
    const { setToast } = useQueryContext()
    return useMutation(
        'create-otc-order', (data) => postApiWithToken(data, token, "otc"),{
            onSuccess: (res) => {
                onClose()
                setToast({
                    message: "trade-success-desc", isError: false, show: true
                })
            },
            onError: () => {
                setLoading(false)
                onClose()
            }
        }
    )
}

export {
    useAvailableCoin,
    useWageCalculator,
    useBankWithdrawMutation,
    useWithdrawMutation,
    useCreateOtcOrder
}