import SubPageLayout from '../../components/layouts/SubPageLayout'
import Text from '../../utils/Text'
import { ContentBody } from '../../styles/components/SupPagesStyles'

export default function AboutUs() {
	return (
		<SubPageLayout header='about-us'>
			<ContentBody>
				<Text tid={'about-us-description'} />
			</ContentBody>
		</SubPageLayout>
	)
}
