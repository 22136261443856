import styled from "styled-components";

const Body = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	height: 60px;
	direction: ${(props) => (props.theme.english ? 'ltr' : 'rtl')};
	background-color: ${(props) => props.theme.primaryBg};
	box-shadow: -2px 0 4px ${(props) => props.theme.mainBg};
	padding: 10px 30px;
	z-index: 9999999999999999999;
`

const BottomBarWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	color: ${(props) => (props.active ? props.theme.clientMain : props.theme.color)};
`

const DText = styled.div`
	font-size: 0.7rem;
`

const TabItem = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	height: 40px;
`

export {
    DText,
    TabItem,
    Body,
    BottomBarWrapper
}