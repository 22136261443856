import { useContext, useEffect } from "react";
import styled from "styled-components"
import { useState } from "react";
import { AnimatePresence } from "framer-motion";
import AmbassadorForm from "../../components/main/AmbassadorForm";
import {useMainContext} from "../../contexts/main";
import {CustomP, TextContainer} from "../../styles/components/SupPagesStyles";
import SubPageLayout from "../../components/layouts/SubPageLayout";



function Ambassadors() {

    const { main: {theme, lang} } = useMainContext()
    const english = lang === "en"

    const [showForm, setShowForm] = useState(false)

    const onFormDismissed = () => {
        setShowForm(false)
    }

    return (
        <SubPageLayout>
                    <TextContainer theme={theme} textAlign={english ? "left" : "right"}>
                        <p>
                            سفیران، شریک تجاری رایاایکسچنج به شمار می روند، سفیررایاایکسچنج درعملیات بازاریابی رایاایکسچنج نقش دارد و اقدامات مشترکی را برای ایجاد یک اکوسیستم تجاری انجام می دهد. اگر طرفدار بلاکچین یا رمزارز می باشید و مایل به شرکت در توسعه برند و تبلیغ رایاایکسچنج هستید، از پیوستن به تیم سفیران رایاایکسچنج استقبال می کنید!
                        </p>
                        <FlexCenter>
                            <Image src={require("../../assets/images/ambassadors.png")} alt=" " />
                        </FlexCenter>
                        <CustomP color={theme === "dark" ? "#00FFCC" : "#FF0099"}>
                            سرویس سفیر آفتاب رایاایکسچنج
                        </CustomP>
                        <p>
                            سفیران  آفتاب رایاایکسچنج می توانند حداکثر 40٪ ازکارمزد تراکنش های تولید شده توسط کاربران معرفی شده خود را به عنوان پاداش معرفی دریافت نمایند، که این مبلغ بهUSDT  تسویه می شود.
                        </p>
                        <p>
                            کسانی که از طریق لینک ریفرال ثبت نام کنند، برای همیشه بر روی کارمزد معاملات خود 10%  تخفیف نسبت به کاربران عادی خواهند داشت.
                        </p>



                        {/*********************  Aftab ******************/}

                        <CustomP color={theme === "dark" ? "#00FFCC" : "#FF0099"}>
                            قوانین سرویس سفیر آفتاب رایاایکسچنج
                        </CustomP>
                        <p>
                            ⦁	کاربر دعوت کننده برای همیشه 40% از کارمزد پرداختی همه ی کاربرانی را که به رایاایکسچنج دعوت نموده، بصورت هفتگی (جمعه ها ساعت 11 صبح ) از رایاایکسچنج دریافت می نماید.
                        </p>
                        <p>
                            ⦁	کاربر دعوت شده (در صورت ثبت نام از طریق لینک دعوت) برای همیشه، 10% بر روی کارمزد معاملات خود از رایاایکسچنج نسبت به کاربران عادی تخفیف خواهد گرفت.
                        </p>
                        <p>
                            ⦁	محدودیتی برای تعداد افراد معرفی شده وجود ندارد.
                        </p>
                        <p>
                            ⦁	درآمد حاصل از لینک ریفرال، بصورت خالص و دقیقا در واحد ارز مورد مبادله بصورت هفتگی (جمعه ها ساعت 11 صبح) به حساب کاربر واریز می گردد.
                        </p>
                        <p>
                            ⦁	سرویس سفیران آفتاب رایاایکسچنج مختص کاربرانی است که حداقل در سطح برنز احراز هویت شده باشند، اگر احراز هویت شما کامل نیست از مسیر پنل حساب کاربری مراحل احراز هویت خود را تکمیل نمایید.
                        </p>
                        <p>
                            ⦁	اگر کاربر زیرمجموعه شما که از طریق لینک دعوت در رایاایکسچنج ثبت نام نموده و از طریق بخش "خرید و فروش آسان OTC" اقدام به مبادله نماید، هیچ بخشی از کارمزد مبادلات ایشان به حساب شما منظور نخواهد شد و فقط در صورت انجام مبادلات در بازار حرفه‌ای ، 40% از کارمزد مبادلات ایشان بصورت هفتگی به حساب شما منظور خواهد شد.
                        </p>
                        <p>
                            ⦁	 شرایط و مقررات سرویس سفیران رایاایکسچنج با اطلاع رسانی قبلی توسط رایاایکسچنج، قابلیت تغییر خواهد داشت.
                        </p>
                        <CustomP style={{ marginTop: "140px" }} color={theme === "dark" ? "#00FFCC" : "#FF0099"}>
                            سرویس سفیرمهتاب رایاایکسچنج
                        </CustomP>
                        <p>
                            درخواست عضویت:
                        </p>
                        <p>
                            بر روی دکمه بالا را کلیک کنید و به سرویس سفیر مهتاب رایاایکسچنج بپیوندید. پس از قبول درخواست، سفیر ما در بازار می شوید. شما می توانید با اختصاص حداقل 2 ساعت در روز درآمد ماهانه‌ای معادل 100 تتر بدست آورید که براساس میزان تاثیرگذاری ماموریت‌های انجام شده اختصاص داده می شود، بخشی از ماموریت‌های مورد درخواست به شرح زیر است:
                        </p>
                        <TableContainer>
                            <Table>
                                <tbody>
                                {details.map(item => (
                                    <Row theme={theme}>
                                        <Column>
                                            {item.first}
                                        </Column>
                                        <Column>
                                            {item.second}
                                        </Column>
                                    </Row>
                                ))}

                                </tbody>
                            </Table>
                        </TableContainer>

                        <BtnContainer>
                            <Button onClick={() => setShowForm(true)} color={theme === "dark" ? "#00FFCC" : "#FF0099"}>
                                درخواست سفیر مهتاب رایاایکسچنج
                            </Button>
                        </BtnContainer>

                        <AnimatePresence exitBeforeEnter>
                            {showForm &&
                                <AmbassadorForm onClose={onFormDismissed} />
                            }
                        </AnimatePresence>

                    </TextContainer>
        </SubPageLayout>
    );
}

const details = [
    { first: "تولید محتوای تبلیغات بازاریابی، شامل پوستر، ویدیو، متن و...", second: "ماموریت 1" },
    { first: "آگهی برند رایاایکسچنج و پاسخ به سوالات متداول کاربران رایاایکسچنج", second: "ماموریت2" },
    { first: "ساختن حداقل یک گروه یا صفحه در شبکه های اجتماعی با حداقل ۲۰۰ نفر فالوور واقعی( شامل گروه اینستاگرام، واتس اپ ، تلگرام یا اجتماع مجازی دیگر)", second: "ماموریت3" },
    { first: "برگزاری حداقل 2 رویداد اجتماعی در فضای مجازی در هر ماه ", second: "ماموریت4" }
]



const FlexCenter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
`

const Image = styled.img`
    width: 600px;

`

const Row = styled.tr`
    padding: 20px;
    background-color: ${props => (props.theme === "dark" && "#070a10") || "#ffffff"};
    &:nth-child(even){
        background-color: ${props => (props.theme === "dark" && "#16191E") || "#E7EAF1"};
    }
    border-radius: 10px;
    font-size: 13px;

    @media screen and (max-width: 767px) {
        font-size: 11px;
        padding: 10px;
    }
`

const Column = styled.td`
    padding: 20px;
    text-align: center;

    &:last-child{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    &:first-child{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }

    @media screen and (max-width: 767px) {
        padding: 10px;
    }
`


const Table = styled.table`
    width: 80%;

    @media screen and (max-width: 767px) {
        width: 95%;
        margin-right: 90px;
    }
`

const TableContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 60px;

    @media screen and (max-width: 767px) {
        overflow-x: scroll;
    }
`


const BtnContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0;
`

const Button = styled.div`
    width: fit-content;
    padding: 10px 30px;
    color: black;
    background-color: ${props => props.color};
    align-self: center;
    border-radius: 8px;
    cursor: pointer;
`


export default Ambassadors;
