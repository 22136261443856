import styled from "styled-components"
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { BsFillPlusCircleFill } from "react-icons/bs";
import {useMainContext} from "../../contexts/main";
import SubPageLayout from "../../components/layouts/SubPageLayout";
import {CustomP, TextContainer} from "../../styles/components/SupPagesStyles";
import Text from "../../utils/Text";
import {DText} from "../../styles/common/CommonStyles";


function Faq() {

    const { main: {theme, lang} } = useMainContext()
    const english = lang === "en"
    const questions = getQuestions(theme)

    return (
        <SubPageLayout>
                    <TextContainer theme={theme} textAlign={english ? "left" : "right"}>
                        <CustomP color={theme === "dark" ? "#00FFCC" : "#FF0099"}>
                            <Text tid="faq" />
                        </CustomP>
                        <FaqBody>
                            {questions.map(q => (
                                <AccordionCol theme={theme} title={q.title} desc={q.desc} />
                            ))}
                        </FaqBody>
                    </TextContainer>
        </SubPageLayout>
    );
}


const AccordionCol = (props) => {

    const { theme, title, desc } = props
    return (
        <Accordion style={{ width: "100%", marginTop: "20px" }}>
            <AccordionSummary
                expandIcon={
                    <BsFillPlusCircleFill
                        color={theme === "dark" ? "9f9f9f" : "#00000095"}
                    />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{
                    background: theme === "dark" ? "#191c20" : "transparent",
                }}
            >
                <DText cFontSize="1rem" main style={{ padding: "8px 0" }}>
                    {title}
                </DText>
                {/*<Typography theme={theme}>*/}
                {/*    {title}*/}
                {/*</Typography>*/}
            </AccordionSummary>
            <AccordionDetails
                style={{
                    background: theme === "dark" ? "#191c20" : "transparent",
                }}
            >
                <Typography>
                    {desc}
                </Typography>
            </AccordionDetails>
        </Accordion>
    )
}


const PWithPadding = styled.p`
    padding: 0 40px;
    margin-bottom: 30px;
    letter-spacing: 1.1px;
    line-height: 1.7;
    color: ${props => (props.theme === "light" && "#131722") || "#ffffff93"} !important;
`;

const getQuestions = (theme) => (
    [
        {
            title: "رایاایکسچنج چیست؟",
            desc:
                <>
                    <P theme={theme}>
                        ⦁   رایاایکسچنج
                    </P>
                    <PWithPadding theme={theme}>
                        رایاایکسچنج یک استارت آپ فینتک تیمی متشکل از نخبگان دانشگاه تهران، شریف و شهید بهشتی با تمرکز بر حوزه بلاکچین بوده که به سرعت در حال توسعه است. یکی از محصولات دانش بنیان ما پلتفرم Exchange یا صرافی آنلاین تمام اتوماتیک مبتنی بر مبادلات رمزارزها با جدیدترین تکنولوژی است که امکان مبادله بیتکوین، اتریوم و سایر رمزارزها را بصورت مستقیم با ریال ایران فراهم می‌کند. تکنولوژی راهکارهای ارائه شده در رایاایکسچنج مطابق الگوهای به روز بین المللی است سطح امنیت، سرعت، سهولت و امکان مبادله بی‌واسطه بین خریدار و فروشنده مهم‌ترین مزایای این پلتفرم برای کاربران در مقایسه با سایر راهکارها هستند.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   واژه رایاایکسچنج
                    </P>
                    <PWithPadding theme={theme}>
                        نام رایاایکسچنج از ترکیب دو واژه ی ترکی ALTIN به معنای "ثروت" (طلا) و لاتین Exchange به معنای "مبادله" ساخته شده است.
                        هدف ما فراهم آوردن بستری امن، به روز و با سرعت برای انجام مبادلات، خرید و فروش مستقیم ارزهای دیجیتال بین خریدار و فروشنده است.
                        پلتفرم صرافی رایاایکسچنج به عنوان واسطه ای میان خریداران و فروشندگان امنیت و ثبات معاملات، پرداخت و وصول دارایی ها را تضمین می کند.
                    </PWithPadding>

                </>
        },
        {
            title: "رمز ارز چیست؟",
            desc:
                <>
                    <P theme={theme}>
                        ⦁   کریپتو کارنسی
                    </P>
                    <PWithPadding theme={theme}>
                        رمزارز یا کریپتوکارنسی یک پول اینترنتی است که با استفاده از رمزنگاری امنیتش
                        تامین شده است. کریپتوکارنسی در واقع از دو کلمه‌ی کریپتو
                        به معنی رمزنگاری و کارنسی به معنای ارز یا پول دیجیتال
                        می‌باشد بیت کوین  اولین و معروفترین کریپتوکارنسی، است.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   کنترل غیرمتمرکز
                    </P>
                    <PWithPadding theme={theme}>
                        کریپتوکارنسی با کنترل غیرمتمرکز شناخته می‌شوند. میزان عرضه و ارزش کریپتوکارنسی توسط فعالیت‌های کاربران و پروتکل‌های بسیار پیچیده موجود در کدها کنترل می‌شود نه تصمیمات آگاهانه بانک‌های مرکزی یا سایر مراجع نظارتی. در این میان، فعالیت‌های استخراج کنندگان(Miners) برای ثبات و عملکرد روان ارزها بسیار مهم است. ماینرها کاربران کریپتوکارنسی هستند که از قدرت محاسباتی زیادی برای ثبت معاملات، واحدهای کریپتوکارنسی تازه ایجاد شده و هزینه‌های معاملاتی استفاده می‌کنند.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   بلاک چین(Blockchain)
                    </P>
                    <PWithPadding theme={theme}>
                        ما برای انجام یک تراکنش مالی از یک واسطه نظیر بانک استفاده می‌کنیم. اما بلاک چین این امکان را به خریداران و فروشندگان (ارسال‌کنندگان و دریافت‌کنندگان پول مجازی) می‌دهد که به صورت مستقیم با هم در ارتباط باشند و نیاز به یک شخص ثالث به عنوان واسطه از بین برود. این شکل از تراکنش را «همتا به همتا» می‌نامند.
                    </PWithPadding>
                    <PWithPadding theme={theme}>
                        بلاک چین از رمزنگاری به منظور ایجاد امنیت درتبادلات و حتی خرید و فروش ارزدیجیتال استفاده می‌کند. بر خلاف سیستم‌های بانکی که دارای یک موقعیت مشخص و در اصطلاح متمرکز عمل می‌کنند، مرکزداده‌ای که بلاک چین‌ها در آن قرار دارند کاملا غیرمتمرکز بوده و در سراسر جهان پخش هستند. محل نگهداری بلاک چین‌ها را در اصطلاح «دفتر کل توزیع شده» می‌نامند. این دفتر کل برای همه اعضای شبکه و با جزئیات در دسترس است و حتی حساب، کتاب‌ها برای همه به‌صورت شفاف وجود دارد. این شبکه در واقع زنجیره‌ای از رایانه‌هایی است که درستی تراکنش‌های صورت گرفته بین شما و طرف مقابل‌تان را تایید می‌کنند و پس از تایید، آن را نیز به بلاک چین اضافه می‌کنند.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   ساختار بلاک چین به زبان ساده
                    </P>
                    <PWithPadding theme={theme}>
                        هر یک از بلوک های موجود در بلاک چین از قسمت های دیجیتالی اطلاعات که شامل سه بخش است تشکیل شده اند:
                    </PWithPadding>
                    <PWithPadding theme={theme}>
                        ⦁       اطلاعات مربوط به تراکنش های شما: به عنوان مثال تاریخ، زمان و مبلغ آخرین خرید بیت کوین شما.
                    </PWithPadding>
                    <PWithPadding theme={theme}>
                        ⦁        اطلاعات  شرکت کنندگان در معاملات: استفاده از امضای دیجیتال به جای نام واقعی شما، خرید شما بدون هیچ گونه اطلاعات شناسایی با استفاده از یک “امضای دیجیتال” منحصر به فرد ثبت می شود.
                    </PWithPadding>
                    <PWithPadding theme={theme}>
                        ⦁       هر بلوک اطلاعات متمایزی با دیگر بلوک ها دارد: همانطور که هریک از ما اثر انگشت داریم تا بتوانیم از یکدیگر متمایز شویم، هر بلوک کد منحصر به فردی به نام “هش” (hash) را ذخیره می کند که به ما امکان می دهد آن را از هر بلوک دیگر تشخیص دهیم. به این ترتیب بلوکهای مربوط به دو معامله با جزئیات یکسان، به دلیل کدهای منحصر به فرد آنها، از هم قابل تفکیک هستند.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   بلاک چین چگونه کار می کند؟
                    </P>
                    <PWithPadding theme={theme}>
                        بیایید برای درک بهتر کارکرد بلاک چین به تراکنش های بیت کوین بپردازیم. بلاک چین تمامی اطلاعات و جزییات تک تک تراکنش‌های صورت گرفته با این ارز دیجیتال را در خود ذخیره می‌کند و اگر یک کاربر بخواهد یک بیت کوین را بیش از دو بار معامله (یعنی کلاهبرداری کند) مانع آن می شود.
                        هر بلوک زمانی که داده های جدید را ذخیره می کند به بلاک چین اضافه می شود. و این گونه با زنجیره ای از چندین بلوک که بهم وصل شده اند عنوان کلی بلاکچین پدید می آید برای اینکه یک بلوک به بلاک چین اضافه شود، باید چهار اتفاق رخ دهد:
                    </PWithPadding>
                    <PWithPadding theme={theme}>
                        ⦁       یک معامله باید انجام شود.
                    </PWithPadding>
                    <PWithPadding theme={theme}>
                        ⦁       پس از خرید، معامله شما باید تأیید شود. این کار را  شبکه رایانه ها که بیش از هزاران رایانه هستند و در سراسر جهان گسترده اند انجام می دهند. این شبکه از طریق رایانه بررسی می کند که معامله مطابق سفارش شما انجام شده باشد.
                    </PWithPadding>
                    <PWithPadding theme={theme}>
                        ⦁     جای ذخیره هر معامله باید در دل یک بلوک باشد. پس از تأیید درستی معامله شما، اطلاعات در یک بلوک اختصاصی ثبت می شود. در آنجا، اطلاعات معامله شما در کنار بی شمار تراکنش مشابه قرار می گیرد.
                    </PWithPadding>
                    <PWithPadding theme={theme}>
                        ⦁      به هر بلوک باید هَش (کد) داده شود: پس از تأیید همه معاملات یک بلوک، باید به آن بلوک یک کد شناسایی منحصر به فرد به نام هَش داده شود. پس از گرفتن هَش اختصاصی، آن بلوک به بلاک چین اضافه می شود.
                    </PWithPadding>
                </>
        },
        {
            title: "صرافی رمز ارزی چیست؟",
            desc:
                <>
                    <PWithPadding theme={theme}>
                        صرافی های رمز ارز یکی از مهم‌ترین ارکان بازار ارزهای رمزنگاری شده هستند. این صرافی‌ها قیمت کوین ها و توکن ها را تعیین می‌کنند و به شما اجازه‌ی تعامل با ارزهای مجازی را می‌دهند. ساده‌ترین راه برای وارد شدن به دنیای رمز ارزها از طریق همین صرافی‌هاست.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   صرافی‌های بلاک چینی چگونه کار می‌کنند؟
                    </P>
                    <PWithPadding theme={theme}>
                        این مراکز امکان مبادله‌ی رمز ارزها، خرید و فروش کوین و تبدیل پول فیات به رمز ارز را فراهم می‌کنند.
                        صرافی‌ های رمز ارزی نرخ کوین و توکن ارزها را مشخص می‌سازند. نرخ رمزارزها معمولا به اعمال خریداران و فروشندگان بستگی دارد، ولی عواملی دیگری هم هستند که بر روی قیمت اثر می‌گذارند.
                        صرافی‌ رمز ارز های مختلف امکانات و کارکردهای متفاوتی دارد. برخی از آن‌ها برای معامله‌گران ساخته شده‌اند، در حالی که بعضی‌ها صرفا برای تبدیل رمز ارزها به پول فیات به وجود آمده‌اند. صرافی‌هایی که برای معامله‌گران عادی هستند به شما اجازه می‌دهند با هزینه‌ای کمتر از صرافی‌های رمز ارز-به-فیات کوین خرید و فروش کنید. علاوه بر این، پلتفرم‌های معاملاتی بابت برداشت پول از حساب هم از کاربران هزینه می‌گیرند.
                        صرافی‌ رمز ارز اساسا مشابه بازارهای بورس است. تنها تفاوت آن‌ها این است که معامله‌گران در بازار بورس دارایی – سهم یا مشتقه – خرید و فروش می‌کنند تا از تغییر قیمت‌ها به سود برسند، در حالی که در صرافی‌های رمز ارزی، معامله‌گران از جفت‌های رمز ارزی استفاده می‌کنند تا از نرخ‌های شدیداً متغیر ارز سود به دست آورند.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   جفت معاملاتی چیست؟
                    </P>
                    <PWithPadding theme={theme}>
                        جفت معاملاتی رمز ارزها به شما اجازه می‌دهد تا از نرخ در حال تغییر ارزها سود کنید. این روش اصلی‌ترین روش کاری معامله‌گران رمز ارزهاست. به یاد داشته باشید که در این روش ترتیب جفت ارزها بسیار مهم است. برای مثال، اگر پیش‌بینی می‌کنید که قیمت بیت‌ کوین (BTC) نسبت به دلار آمریکا (USD) بالا خواهد رفت، باید جفت BTC/USD را بخرید. ولی اگر فکر می‌کنید که قیمت بیت کوین نسبت به دلار آمریکا ریزش خواهد داشت، باید جفت USD/BTC را بخرید.
                        برخی از صرافی‌ها پول‌های فیات را نادیده می‌گیرند و فقط جفت‌های رمز ارز دارند. محبوب‌ترین جفت‌های رمز ارز BTC/LTC یا LTC/BTC و ETH/BTC یا BTC/ETH هستند.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   چرا قیمت صرافی‌ها با هم متفاوت است؟
                    </P>
                    <PWithPadding theme={theme}>
                        چون صرافی‌ها به یکدیگر وصل نیستند. به همین خاطر قیمت‌ها بسته به خرید و فروشی که در همان صرافی انجام می‌شود، تغییر می‌کند.
                        هر صرافی‌ رمز ارز قیمت بیت کوین را بر اساس حجم معاملات، و همچنین عرضه و تقاضای کاربرانش تعیین می‌کند. یعنی هرچه صرافی بزرگ‌تر باشد، قیمت آن تناسب بیشتری با بازار خواهد داشت. چیزی تحت عنوان قیمت «ثابت» یا «منصفانه» برای بیت کوین یا سایر رمز ارزها نداریم. قیمت همیشه و در هر لحظه بر اساس شرایط بازار تعیین می‌شود.
                        خیلی از سرویس‌های خبری – مثل سرویس خبری گوگل – از برآیند قیمت بیت کوین و سایر کوین‌ها استفاده می‌کنند. کوین‌تلگراف شاخص مخصوصی برای قیمت بیت کوین، اتریوم و سایر ارزها دارد که از محاسبه‌ میانگین قیمت در ۲۷ صرافی‌ رمز ارز پرطرفدار به دست می‌آید.
                    </PWithPadding>
                </>
        },
        {
            title: "چگونه و از کجا شروع کنم؟",
            desc:
                <>
                    <PWithPadding theme={theme}>
                        به سادگی ابتدا با استفاده از شماره موبایل یا ایمیل شخصی یک حساب کاربری ایجاد نمایید. پس از احراز هویت، می‌توانید مبلغ ریال یا رمزارز مورد نظر خود را به کیف پول شخصیتان واریز نموده و اقدام به خرید و فروش به صورت آنلاین نمایند.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁     چه طور رمز ارز بخرم؟
                    </P>
                    <PWithPadding theme={theme}>
                        پس از ثبت نام و افتتاح حساب در سایت رایاایکسچنج ، احراز هویت و تأیید حساب، کاربران محترم می توانند با استفاده از درگاه بانکی اقدام به شارژ حساب ریالی خود نمایند. پس از شارژ حساب، بلافاصله با استفاده از سیستم سفارش گذاری می توانند درخواست خرید ارز دیجیتال مورد نیاز خود را با قیمت و حجم دلخواه در سایت ثبت نموده و در صورت وجود فروشنده، سفارش خود را تکمیل نمایند. پس از تکمیل خرید، کیف پول مربوط به ارز دیجیتال خریداری شده به صورت اتوماتیک شارژ خواهد شد. سپس، امکان درخواست برداشت ارز دیجیتال از کیف پول اختصاصی هر کاربر میسر خواهد بود. برای کسب اطلاعات بیشتر به راهنمای سایت مراجعه نمایید.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁     چطور رمز ارز بفروشم؟
                    </P>
                    <PWithPadding theme={theme}>
                        پس از افتتاح حساب در سایت رایاایکسچنج، احراز هویت و تأیید حساب توسط مدیریت سایت، کاربران محترم می توانند با استفاده از آدرس اختصاصی، اقدام به انتقال ارز دیجیتال به کیف پول خود نمایند. پس از تأیید تراکنش در شبکه بلاک‌چین (blockchain)،کیف پول مربوط به رمزارز انتقال یافته به صورت اتوماتیک شارژ خواهد شد. پس از این مرحله، این امکان وجود دارد تا کاربر، درخواست فروش رمزارز مورد نظر خود را با قیمت و حجم دلخواه ثبت نماید و در صورت وجود خریدار، سفارش خود را تکمیل نماید. پس از انجام فروش، کیف پول ریالی کاربر به صورت اتوماتیک شارژ خواهد شد. سپس، امکان درخواست برداشت ریال از کیف پول به حساب بانکی میسر خواهد بود. برای کسب اطلاعات بیشتر به راهنمای سایت مراجعه نمایید.
                    </PWithPadding>

                </>
        },
        {
            title: "میزان کارمزدهای  رایاایکسچنج در معاملات چه مقدار است؟",
            desc:
                <>

                    <PWithPadding theme={theme}>
                        در حال حاضر کارمزد یا هزینه معاملات در رایاایکسچنج به صورت درصدی و براساس سطوح مختلف از 0.2 تا 0.35 درصد برحسب حجم معاملات کاربر در سی روز گذشته محاسبه می گردد. به دلیل عدم وجود قانون در زمینه تبادل ارزهای دیجیتال در ایران، در حال حاضر هیچ گونه هزینه اضافی اعم از مالیات و ... برای کاربران گرامی منظور نمی شود. برای اطلاعات بیشتر به صفحه کارمزدهای رایاایکسچنج مراجعه فرمایید.
                    </PWithPadding>
                </>
        },
        {
            title: "احراز هویت چیست و چگونه است؟",
            desc:
                <>
                    <PWithPadding theme={theme}>
                        شروع ثبت نام با استفاده از شماره موبایل و ایمیل معتبر امکان پذیر خواهد بود سپس کاربر باید اقدام به تکمیل اطلاعات پروفایل خود نماید. در صرافی رایاایکسچنج مراحل احراز هویت با استفاده از تکنولوژی هوش مصنوعی انجام می پذیرد که مدت انجام مراحل را به کتر از 30 دقیقه کاهش می دهد. کاربر ملزم به ارائه شماره تلفن همراه، اسکن رنگی کارت ملی، پاسپورت یا شناسنامه جدید، شماره کارت بانکی، حساب و شبا به نام خود کاربر می باشد. همچنین ارائه  آدرس و تلفن ثابت به همراه قبض حاوی آدرس، تصویر سلفی طبق الگوی سایت و شماره همراه به نام خود کاربر مورد نیاز است.
                    </PWithPadding>
                    <P theme={theme}>
                        ⦁   چرا باید اطلاعات شخصی خود را برای خرید و فروش ارزهای دیجیتال ارائه دهم؟
                    </P>
                    <PWithPadding theme={theme}>
                        رایاایکسچنج، مطابق با مقررات جهانی و داخلی، ملزم به احراز هویت کاربران محترم جهت ارائه خدمات خرید و فروش ارزهای دیجیتال خواهد بود. تیم رایاایکسچنج متعهد به حفظ محرمانگی  اطلاعات هویتی کاربران بوده و به منظور حفظ امنیت سرمایه ی کاربران محترم حداکثر محافظت لازم را به عمل می آورد.
                    </PWithPadding>
                </>
        },
        {
            title: "چه رمزارزهایی می توانم واریز یا برداشت نمایم؟",
            desc:
                <>
                    <PWithPadding theme={theme}>
                        در حال حاضر در رایاایکسچنج امکان تبدیل ریال به بیت کوین، لایت کوین، کاردانو، اتریوم ،تتر، ریپل، بایننس کوین، استلار، ایاس و بالعکس وجود دارد. همچنین امکان تبدیل رمزارزها به رمزارز تتر و بالعکس نیز در قالب بازار حرفه ای خواهد بود. به مرور رمزارزهای بیشتری به بازار مبادلاتی رایاایکسچنج اضافه خواهد شد. برای اطلاع از آخرین ارزهای موجود میتوانید صفحه بازارها در پنل کاربری را ملاحظه بفرمایید.
                    </PWithPadding>
                </>,
        },
        {
            title: " کیف پول رمزارزی چیست؟",
            desc:
                <>
                    <PWithPadding theme={theme}>
                        Wallet یک وسیله یا یک برنامه یا یک سرویس است که کلید عمومی یا خصوصی را برای انجام تراکنش ذخیره می‌کند. یعنی می‌تواند به امر نقل و انتقالات رمز ارز ها و یا حتی ذخیره رمز ارز ها بپردازد. کیف پول دیجیتال می‌تواند برای پیگیری مالکیت، دریافت یا خرج رمزارز استفاده شود بیت‌کوین و سایر رمزارزها ، در یک دفتر کل غیر متمرکز که به‌ صورت عمومی قابل دسترسی است و زنجیره بلوکی(BlockChain) نامیده میشود، ذخیره میشوند.
                    </PWithPadding>
                </>
        },
        {
            title: "کاربرد کیف پول رمز ارزی چیست؟",
            desc:
                <>
                    <PWithPadding theme={theme}>
                        یک کیف پول رمزارز، مانند حساب بانکی، شامل یک جفت کلید رمزنگاری عمومی و خصوصی است. یک کلید عمومی به سایر کیف های پول اجازه پرداخت میدهد، و کلید خصوصی اجازه خروج ارز از کیف پول را فراهم میکند. به زبان ساده، کیف پول یک برنامه نرم افزاری است که با بلاک چین همگام سازی می شود. از طریق این همگام سازی، می توان مقدار ارز رمزنگاری شده (دریافت و پرداخت شده) یک کیف پول خاص را مشاهد کرد. در مورد کیف پول های سخت افزاری، آنها پلی بین کیف پول نرم افزاری شما و بلاک چین هستند.
                    </PWithPadding>
                </>
        },
        {
            title: "واریز به کیف پول یا برداشت از آن از چه طریقی ممکن است؟ چه هزینه هایی در بر خواهد داشت؟",
            desc:
                <>
                    <PWithPadding theme={theme}>
                        در حال حاضر به هر کاربر به ازای هر ارز یک کیف پول اختصاص داده می شود. واریز ارزهای دیجیتال (کوین ها) از طریق آدرس اختصاصی کیف پول مربوط به هر کاربر ممکن خواهد بود. در حال حاضر رایاایکسچنج هیچ گونه هزینه اضافی بابت واریز یا برداشت از کیف پول کسر نمی نماید. برای برداشت ریال به دلیل استفاده از خدمات پرداخت یار برای تسویه بانکی، مبلغ یک درصد تا سقف ۴ هزار تومان از درخواست ، برای برداشت کسر و به حساب بانکی تعیین شده توسط کاربر، واریز خواهد شد. برداشت ارز دیجیتال نیز، با وارد کردن آدرس کیف پول مقصد در سیستم ممکن خواهد بود. هزینه‌ی برداشت رمزارز نیز صرفاً مبلغ مورد نیاز برای انتقال آن در شبکه بلاک چین خواهد بود و سایت رایاایکسچنج هیچگونه هزینه اضافی را دریافت نخواهد کرد. برای اطلاعات بیشتر به صفحه‌ی کارمزد ها مراجعه فرمایید.
                    </PWithPadding>
                </>
        }
    ]
)




const FaqBody = styled.div`
  width: 100%;
  padding: 0 90px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;

  @media only screen and (max-width: 560px) {
    padding: 0;
  }
`;

const Typography = styled.h3`
  font-size: 16px;
  color: ${props => props.theme.color};
`;

const P = styled.p`
    color: ${props => props.theme === "light" && "#131722"};
`




export default Faq;
