import styled from 'styled-components'

export const SubPageHead = styled.h3``
export const SubPageDescription = styled.p`
	//font-size: 0.5rem;
`

export const ContentHeadLine = styled.h3`
	color: ${(props) => props.theme.clientMain};
	white-space: break-spaces;
`

export const ContentBody = styled.p`
	line-height: 26px;
	white-space: break-spaces;

	span {
		//padding: 5px;
	}
`

export const TextContainer = styled.div`
    width: ${props => props.width || "100%"};
    padding: 80px 60px;
    font-size: 15px;
    color: ${props => (props.theme === "dark" && "#EFEFEF") || "#070a10"};
    text-align: ${props => props.textAlign};

    @media screen and (max-width: 960px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {
        padding: 40px 30px;
        font-size: 13px;
    }
`


export const CustomP = styled.p`
  color: ${props => props.theme.customP};
  font-weight: bold;
  letter-spacing: 1.5px;
  margin-top: 20px;
  font-size: 17px;


  @media screen and (max-width: 767px) {
        font-size: 14px;
    }
`

export const BoxContainer = styled.div`
  width: 40%;
  padding: 40px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 960px) {
      width: 100%;
  }
`


export const DetailsContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
`

export const P = styled.p`
    color: ${props => props.theme.color};
    font-size: 0.9rem;
`
