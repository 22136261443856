const BASE_URL = 'https://dev.exdev.ir/v1/'
const SOCKET_URL = 'https://dev.exdev.ir/'
const HOME = "https://ce3.datest.ir/"

const TABLET_SIZE = 1050
const MOBILE_SIZE = 768

const EqualSvg = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24">
            <path d="M18.9 9.2C18.1 10.1 16.6 11 15 11C13.5 11 12.6 10.5 11.8 10.1C11 9.8 10.2 9.3 8.9 9.3C7.7
             9.3 6.6 10 6 10.6L5 9.1C5.9 8.2 7.3 7.2 8.9 7.2C10.4 7.2 11.3 7.8 12.1 8.1C12.9 8.4 13.7 9 15
             9C16.2 9 17.3 8.2 17.9 7.6L18.9 9.2M19 14.1C18.1 15 16.7 16 15.1 16C13.6 16 12.7 15.5 11.9
             15.1C11.1 14.8 10.3 14.2 9 14.2C7.8 14.2 6.7 15 6.1 15.6L5.1 14C6 13.1 7.4 12.1 9 12.1C10.5
              12.1 11.4 12.6 12.2 13C13 13.3 13.8 13.8 15.1 13.8C16.3 13.8 17.4 13 18 12.4L19 14.1Z"  fill="#ffc800" />
        </svg>
    )
}

export {
    BASE_URL,
    SOCKET_URL,
    HOME,
    TABLET_SIZE,
    MOBILE_SIZE,
    EqualSvg
}
