import  { useEffect, useState } from "react";
import styled, { ThemeProvider, css } from "styled-components";
import Main, {useMainContext} from "../../contexts/main";
import MainLayout from "../../components/layouts/MainLayout";


const Ecosystem = () => {

    const { main: {theme} } = useMainContext()

    const [itemChangedOnMouse, setItemChangedOnMouse] = useState(false)
    const [itemFocused, setItemFocused] = useState(0)
    useEffect(() => {
        let timer;
        if (!itemChangedOnMouse) {
            timer = setTimeout(() => {
                if (itemFocused === 8) {
                    setItemFocused(0)
                } else {
                    setItemFocused(itemFocused => itemFocused + 1)
                }
            }, 3000)
        }

        return () => clearTimeout(timer)
    }, [itemFocused, itemChangedOnMouse])

    const DrawrayaexItem = ({ item, index }) => {

        const prop = item.props
        const { top, left } = item.props.main
        const focused = index === itemFocused

        const toggleOnItemFocused = () => {
            setItemChangedOnMouse(true)
            setItemFocused(index)
        }

        const toggleOffItemFocused = () => {
            setItemChangedOnMouse(false)
        }

        return (
            <>
                <ItemPosition
                    onMouseEnter={toggleOnItemFocused}
                    onMouseLeave={toggleOffItemFocused}
                    focused={focused}
                    style={{ top: prop.main.top, left: `calc(50% - ${left}px)` }}
                >
                    <img
                        width={48}
                        height={48}
                        src={require(`../../assets/images/card-icons/${item.image}.png`)}
                        alt=" "
                    />
                </ItemPosition>

                {item.props.rightFirst &&
                    <>
                        <LittleCircle style={{ top: top + 40, left: `calc(50% - ${left - 80}px)` }} />
                        <LittleCircle style={{ top: top + 60, left: `calc(50% - ${left - 140}px)` }} />
                    </>
                }

                {item.props.leftFirst &&
                    <>
                        <LittleCircle style={{ top: top + 40, left: `calc(50% - ${left + 40}px)` }} />
                        <LittleCircle style={{ top: top + 60, left: `calc(50% - ${left + 100}px)` }} />
                    </>
                }

                {item.props.leftSecond &&
                    <>
                        <LittleCircle style={{ top: top + 70, left: `calc(50% - ${left + 30}px)` }} />
                        <LittleCircle style={{ top: top + 120, left: `calc(50% - ${left + 60}px)` }} />
                    </>
                }
                {item.props.rightSecond &&
                    <>
                        <LittleCircle style={{ top: top + 70, left: `calc(50% - ${left - 60}px)` }} />
                        <LittleCircle style={{ top: top + 120, left: `calc(50% - ${left - 90}px)` }} />
                    </>
                }
                {item.props.leftMiddle &&
                    <>
                        <LittleCircle style={{ top: top + 70, left: `calc(50% - ${left - 22}px)` }} />
                        <LittleCircle style={{ top: top + 120, left: `calc(50% - ${left - 32}px)` }} />
                    </>
                }
                {item.props.rightMiddle &&
                    <>
                        <LittleCircle style={{ top: top + 70, left: `calc(50% - ${left - 22}px)` }} />
                        <LittleCircle style={{ top: top + 120, left: `calc(50% - ${left - 12}px)` }} />
                    </>
                }
                {item.props.leftBSec &&
                    <>
                        <LittleCircle style={{ top: top + 65, left: `calc(50% - ${left - 47}px)` }} />
                        <LittleCircle style={{ top: top + 100, left: `calc(50% - ${left - 82}px)` }} />
                    </>
                }
                {item.props.rightBSec &&
                    <>
                        <LittleCircle style={{ top: top + 65, left: `calc(50% - ${left + 12}px)` }} />
                        <LittleCircle style={{ top: top + 100, left: `calc(50% - ${left + 52}px)` }} />
                    </>
                }
                {item.props.rightEnd &&
                    <>
                        <LittleCircle style={{ top: top + 30, left: `calc(50% - ${left - 82}px)` }} />
                        <LittleCircle style={{ top: top + 30, left: `calc(50% - ${left - 152}px)` }} />
                    </>
                }
            </>
        )
    }

    return (
        <MainLayout>
            <ThemeProvider theme={themeColors[theme]}>
                <Wrapper>
                    <Body>
                        <Header>Ecosystem</Header>
                        {ecosystemDetails.map((item, index) => (
                            <DrawrayaexItem index={index} item={item} />
                        ))}
                        <DescriptionWrapper>
                            <DescriptionBody>
                                <img alt=" "
                                     src={require(`../../assets/images/card-icons/${ecosystemDetails[itemFocused].image}.png`)}
                                     width={64}
                                     height={64}
                                />
                                <DescHeader>
                                    {ecosystemDetails[itemFocused].title}
                                </DescHeader>
                                <DescContent>
                                    {ecosystemDetails[itemFocused].content}
                                </DescContent>
                            </DescriptionBody>
                        </DescriptionWrapper>
                    </Body>
                </Wrapper>
            </ThemeProvider>
        </MainLayout>
    )
}

const themeColors = {
    light: {
        bgColor: "#f8f8f8",
        color: "#13121C",
        secondary: "#13121C98"
    },
    dark: {
        bgColor: "#13121C",
        color: "white",
        secondary: "#ffffff98"
    }
}


const ecosystemDetails = [

    {
        image: "rayaex-academy", title: "Alt Academy", content: "ارائه جدیدترین آموزش های حوزه رمزارز، بلاکچین و فینتک",
        props: { main: { top: 200, left: 32 }, leftFirst: true, rightFirst: true }
    },
    {
        image: "rayaex-r&d", title: "R&D", content: "تیم حرفه ای تحقیق و توسعه رایاایکسچنج گسترش مرزهای تکنولوژی مبتنی بر بلاکچین",
        props: { main: { top: 280, left: -150 }, rightSecond: true }
    },
    {
        image: "rayaex-chain", title: "Chain", content: "شبکه بلاکچین اختصاصی رایاایکسچنج",
        props: { main: { top: 430, left: -220 }, rightMiddle: true }
    },
    {
        image: "rayaex-club", title: "Club", content: "باشگاه مشتریان رایاایکسچنج",
        props: { main: { top: 580, left: -200 }, rightBSec: true }
    },
    {
        image: "rayaex-compliant", title: "Community", content: "به شبکه های اجتماعی رایاایکسچنج بپیوندید",
        props: { main: { top: 690, left: -70 } }
    },
    {
        image: "8", title: "Support 24/7", content: "پشتیبانی 24 ساعته 7روز هفته",
        props: { main: { top: 690, left: 140 }, rightEnd: true }
    },
    {
        image: "copy-trading", title: "Copy trading", content: "تکنولوژی رونوشت معاملات تریدرهای حرفه ای",
        props: { main: { top: 580, left: 250 }, leftBSec: true }
    },
    {
        image: "rayaex-wallet", title: "Wallet", content: "کیف پول اختصاصی رایاایکسچنج پشتیبانی از کیف پول های رایج جهان",
        props: { main: { top: 430, left: 280 }, leftMiddle: true }
    },
    {
        image: "rayaexopedia", title: "rayaexopedia", content: "دایره المعارف کریپتوکارنسی ",
        props: { main: { top: 280, left: 200 }, leftSecond: true }
    },
]


const Wrapper = styled.div`
    min-height: 100vh;
    min-width: 1200px;
    padding: 50px 0;
    background-color: ${props => props.theme.bgColor};
`


const Body = styled.div`
    min-width: 1200px;
    /* overflow-y: auto; */
    text-align: center;
    min-height: 100vh;
    position: relative;
    margin-bottom: 40px;
`

const Header = styled.h1`
    color: ${props => props.theme.color} !important;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 5px;
    padding-top: 100px;
`

const ItemPosition = styled.div`
    position: absolute;
    z-index: 2;

    &:after{
        position: absolute;
        content: ' ';
        border-radius: 50%;
        width: 80px;
        height: 80px;
        top: -18px;
        right: -20px;
        cursor: pointer;

        ${props => props.focused && css`
            border: 4px solid #00FFCC;
        `}
    }

`

const LittleCircle = styled.div`
    background-color: ${props => props.theme.color};
    border-radius: 50%;
    width: 9px;
    height: 9px;
    position: absolute;
`

const DescriptionWrapper = styled.div`
    position: absolute;
    top: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
`

const DescriptionBody = styled.div`
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const DescHeader = styled.div`
    font-size: 20px;
    color: ${props => props.theme.color};
    margin-top: 30px;
    letter-spacing: 1.5px;
    font-weight: 600;
`

const DescContent = styled.div`
    font-size: 14px;
    color: ${props => props.theme.secondary};
    margin-top: 15px;
`

export default Ecosystem;