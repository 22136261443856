import { Card, CardContentBox, CardGrid } from '../../styles/components/CardStyles'
import { Background, DText, Flex, Padding } from '../../styles/common/CommonStyles'
import Text from '../../utils/Text'

const Cards = () => {
	return (
		<Flex fw>
			<Background bg='primaryBg'>
				<Padding padding='40px'>
					<CardGrid>
						{data.map((card) => (
							<Card>
								<img
									width={imageSize}
									height={imageSize}
									src={require(`../../assets/images/card-icons/${card.image}.png`)}
									alt=' '
								/>
								<DText fontSize='b' style={{ margin: '10px 0' }} primary>
									<Text tid={card.header} />
								</DText>
								{typeof card.content === 'string' ? (
									<DText fontSize='s'>
										<Text tid={card.content} />
									</DText>
								) : (
									<CardContentBox>
										{card.content.map((text) => (
											<DText fontSize='s'>
												<Text tid={text} />
											</DText>
										))}
									</CardContentBox>
								)}
							</Card>
						))}
					</CardGrid>
				</Padding>
			</Background>
		</Flex>
	)
}

/**
 * add content here
 * image (image name - string) .. header (string) .. content (can be []string || string)
 */
const imageSize = 72
const data = [
	{
		image: '4',
		header: 'احراز هویت سریع و هوشمند',
		content: [
			"سرویس احراز هویت دیجیتال با فن آوري E-KYC",
			"یادگیری ماشین MACHINE LEARNING",
			"الگوریتم تشخیص زنده بودن LIVENESS DETECTION"
		],
	},
	{
		image: '3',
		header: 'تجارت امن و سریع',
		content: [
			"تضمین ثبات و همزمانی هزاران سفارش",
			"موتور تطبیق سریع و اختصاصی"
		],
	},
	{
		image: '2',
		header: 'ساده ، راحت اما قدرتمند',
		content: [
			"خرید فروش و تجارت کریپتوکارنسی در هر سطحی از مهارت کاربران مبتدي، متوسط و حرفه اي"
		],
	},
	{
		image: '1',
		header: 'اعتماد کاربران مهمترین سرمایه رایاایکسچنج',
		content: [
			"بالاترین سطح امنیت براي حفظ اطلاعات و حریم خصوصی کاربر"
		],
	},
	{
		image: '8',
		header: '"پشتیبانی 24/7',
		content: [
			"7 روز هفته در 24 ساعت شبانه روز پاسخگوي شما هستیم"
		],
	},
	{
		image: '7',
		header: 'کارمزد و هزینه خدمات',
		content: [
			"ارائه نرخ کارمزد شفاف، دقیق، رقابتی"
		],
	},
	{
		image: '6',
		header: 'سرویس تامین تتر',
		content: [
			"انتقال تتر به صورت محافظت شده از رایاایکسچنج به کیف پول اختصاصی کاربر",
			"کاهش ریسک فریز شدن تتر",
			"کاهش ریسک بلوکه شدن دارایی دیجیتال کاربر "
		],
	},
	{
		image: '5',
		header: 'سرویس سفیران رایاایکسچنج',
		content: [
			"سفیر آفتاب",
			"سفیر مهتاب"
		],
	},
]

export default Cards
