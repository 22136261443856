import styled from "styled-components";
import Text from "../../utils/Text";
import SubPageLayout from "../../components/layouts/SubPageLayout";
import {useMainContext} from "../../contexts/main";
import {CustomP, TextContainer} from "../../styles/components/SupPagesStyles";


const Fees = () => {

    const { main: {theme, lang} } = useMainContext()
    const english = lang === "en"

    return (
        <SubPageLayout noCard>
                <Card id="card-1" theme={theme} marginTop="-40px">
                    <FastScrollContainer left={english} right={!english} width={english ? "400px" : "250px"}>
                        <FastScrollItem color={theme === "dark" ? "#00FFCC" : "#FF0099"} onClick={() => window.location.replace("#card-1")}>
                            <Text tid="wages" />
                        </FastScrollItem>
                        <FastScrollItem color={theme === "dark" ? "#00FFCC" : "#FF0099"} onClick={() => window.location.replace("#card-2")}>
                            <Text tid="crypto-withdraw" />
                        </FastScrollItem>
                        <FastScrollItem color={theme === "dark" ? "#00FFCC" : "#FF0099"} onClick={() => window.location.replace("#card-3")}>
                            <Text tid="rial-withdrawl" />
                        </FastScrollItem>
                    </FastScrollContainer>
                    <TextContainer theme={theme} textAlign={english ? "left" : "right"}>
                        <p>
                            شفافیت، اعتبار رایاایکسچنج است از
                            این رو تمامی هزینه‌ها و کارمزدهای مبادلات در رایاایکسچنج به صورت
                            شفاف و دقیق به اطلاع کاربران می‌رسد. ثبت‌نام و استفاده از
                            سامانه‌ی رایاایکسچنج کاملا رایگان می‌باشد و تنها طبق توضیحات مندرج
                            در این صفحه، از برخی معاملات و مبادلات مالی انجام شده
                            در سامانه رایاایکسچنج، هزینه‌ی کمی به عنوان کارمزد کسر می‌شود.
                        </p>
                        <p>
                            کارمزد معاملات در سامانه رایاایکسچنج بصورت درصد از مبلغ کل معامله است
                            و محاسبه‌ی آن بر اساس ملاحظات زیر صورت می‌گیرد.
                        </p>
                        <p>⦁کارمزد از هر دو طرف معامله اخذ می‌شود.</p>
                        <p>⦁	کارمزد به صورت درصد از حجم دارایی درخواست شده  محاسبه می گردد؛ به طور مثال
                            اگر به عنوان فروشنده، بخواهید در برابر بیت‌کوین،
                            ریال دریافت کنید کارمزد به صورت درصد از ریال
                            دریافت می‌شود، و بالعکس اگر به عنوان خریدار
                            بخواهید با ریال خود بیت‌کوین خریداری نمایید،
                            کارمزد به صورت درصد از بیت‌کوین دریافت خواهد شد.</p>
                        <p>
                            ⦁	در هنگام ثبت معاملات از طریق سامانه‌ی رایاایکسچنج، مبلغ
                            دقیق کارمزد برای آن معامله برای شما نمایش داده خواهد شد.
                        </p>
                        <p>
                            ⦁	بسته به حجم معاملات کاربر، کارمزد معاملات طبق جدول زیر محاسبه می گردد:
                        </p>

                        <TableContainer>
                            <Table>
                                <HeaderRow theme={theme} width="25%">
                                    <Column><Text tid="wages" /></Column>
                                    <Column><Text tid="tradeBound" /></Column>
                                    <Column><Text tid="level" /></Column>
                                    <Column><Text tid="singinpast" /></Column>
                                </HeaderRow>
                                {wageData.map(wage => (
                                    <Row theme={theme} width="25%">
                                        <Column>{wage.wage}</Column>
                                        <Column><Text tid={wage.bound} /></Column>
                                        <Column><Text tid={wage.level} /></Column>
                                        <Column><Text tid={wage.time} /></Column>
                                    </Row>
                                ))}
                            </Table>
                        </TableContainer>

                        <p>
                            معاملات انجام شده در بازارهای حرفه‌ای رایاایکسچنج، مستقل از حجم به صورت ویژه
                            با کارمزد 0.13% برای میکر و 0.15% برای تیکر محاسبه می‌شود. منظور
                            از بازارهای حرفه‌ای، بازارهای با مقصد تتر مانند بیت‌کوین/تتر است.
                        </p>
                        <p>
                            میزان کارمزد معاملات در سطح تریدر برای بازارهای حرفه‌ای، همانند دیگر
                            سطوح کاربری بوده و در بازارهای ریالی، مطابق با جدول زیر می‌باشد.
                        </p>

                        <p>
                            درجدول ذیل شما می توانید
                            کارمزد های مربوط به هر سطح را برای سفارش گذار
                            (maker) و یا پذیرنده سفارش (taker) ملاحظه فرمایید.
                        </p>
                        <TableContainer>
                            <Table>
                                <HeaderRow theme={theme} width="50%">
                                    <Column><Text tid="trade-type" /></Column>
                                    <Column><Text tid="pro-wage-desc" /></Column>
                                </HeaderRow>
                                <Row theme={theme} width="50%">
                                    <Column>Maker</Column>
                                    <Column>0.1%</Column>
                                </Row>
                                <Row theme={theme} width="50%">
                                    <Column>Taker</Column>
                                    <Column>0.15%</Column>
                                </Row>
                            </Table>
                        </TableContainer>

                        <p>
                            معاملات انجام شده در زمان فعال بودن حساب تریدر، در مجموع معاملات سی روز
                            اخیر شما و سطح کارمزد شما پس از غیرفعال کردن این طرح محسوب نخواهد شد.
                        </p>
                        <p>
                            1-سطح کاربری (Level): کاربران بر اساس آنکه چه مراحلی از روند ثبت نام را طی کرده و
                            اطلاعات خواسته شده را وارد نموده و مورد
                            تایید پلتفورم قرار گرفته است، یکی
                            از سطوح به ایشان اختصاص داده می شود.
                        </p>
                        <p>
                            ۲-  سفارش گذار (Market Maker): در چرخه  یک معامله مشخص ، شخصی که ابتدا پیشنهاد خرید
                            و یا فروش بیت کوین را ارائه می دهد و
                            منتظر پذیریش این پیشنهاد توسط دیگر کاربران
                            می ماند را سفارش گذار و یا Market Maker می گویند.
                        </p>
                        <p>
                            ۳-  پذیرنده  سفارش (Market Taker): در چرخه  یک معامله مشخص ، شخصی که پیشنهاد خرید و یا
                            فروش بیت کوین یک سفارش گذار را قبول می کند و باعث انجام آن معامله می شود را  پذیرنده  سفارش و یا Market Taker می گویند.
                        </p>

                        <p>
                            ۴-  کارمزد سفارش گذار (Maker Fee): به کارمزدی  اطلاق می شود  که یک سفارش گذار (Market Maker)
                            باید به اکسچنج بابت انجام معامله مورد نظر پرداخت نماید وبصورت درصدی از رقم مورد معامله بیان می شود.
                        </p>

                        <p>
                            ۵-  کارمزد پذیرنده سفارش (Taker Fee): به کارمزدی  اطلاق می شود که یک پذیرنده  سفارش (Market Taker)
                            باید به اکسچنج بابت انجام معامله مورد نظر پرداخت نماید وبصورت درصدی
                            از رقم مورد معامله بیان می شود.
                        </p>
                    </TextContainer>
                </Card>

                <Card id="card-2" theme={theme} marginTop="20px">
                    <TextContainer theme={theme} textAlign={english ? "left" : "right"}>
                        <CustomP >
                            واریز و برداشت رمزارز
                        </CustomP>
                        <p>
                            کارمزد واریز و برداشت دارایی‌های دیجیتال به
                            نوع کوین بستگی دارد و در حال حاضر به شرح زیر است:
                        </p>
                        <TableContainer>
                            <Table>
                                <HeaderRow theme={theme} width="20%">
                                    <Column><Text tid="digital-property" /></Column>
                                    <Column><Text tid="withdraw-fee" /></Column>
                                    <Column><Text tid="min-withdraw" /></Column>
                                    <Column><Text tid="deposite-fee" /></Column>
                                    <Column><Text tid="min-deposite" /></Column>
                                </HeaderRow>
                                {depositeWithdrawDetail.map(detail => (
                                    <Row theme={theme} width="20%">
                                        <Column>{detail.property}</Column>
                                        <Column>{detail.withdraw}</Column>
                                        <Column>{detail.minWithdraw}</Column>
                                        <Column>{detail.deposite}</Column>
                                        <Column>{detail.minDeposite}</Column>
                                    </Row>
                                ))}
                            </Table>
                        </TableContainer>
                    </TextContainer>
                </Card>

                <Card id="card-3" theme={theme} marginTop="20px">
                    <TextContainer theme={theme} textAlign={english ? "left" : "right"}>
                        <CustomP >
                            واریز و برداشت ریال
                        </CustomP>
                        <p>
                            کارمزد واریز ریال صفر است.
                        </p>
                        <TableContainer>
                            <Table>
                                <HeaderRow theme={theme} width="50%">
                                    <Column><Text tid="rial-withdraw" /></Column>
                                    <Column><Text tid="withdraw-fee" /></Column>
                                </HeaderRow>
                                <Row theme={theme} width="50%">
                                    <Column><Text tid="till400" /></Column>
                                    <Column>1%</Column>
                                </Row>
                                <Row theme={theme} width="50%">
                                    <Column><Text tid="till50mil" /></Column>
                                    <Column><Text tid="4000t" /></Column>
                                </Row>
                                <Row theme={theme} width="50%">
                                    <Column><Text tid="morethan50" /></Column>
                                    <Column><Text tid="4000tper50" /></Column>
                                </Row>
                            </Table>
                        </TableContainer>

                        <CustomP >
                            زمان بندی تسویه ریالی
                        </CustomP>
                        <p>
                            زمان تسویه ریالی با کاربران در حال حاضر بر طبق جداول زیر انجام خواهد شد.
                        </p>
                        <CustomP  style={{ textAlign: "center" }}>
                            زمان بندی تسویه ریالی در روزهای کاری
                        </CustomP>
                        <TableContainer>
                            <Table>
                                <HeaderRow theme={theme} width="33%">
                                    <Column><Text tid="dest-acc" /></Column>
                                    <Column><Text tid="inweek" /></Column>
                                    <Column><Text tid="settlement" /></Column>
                                </HeaderRow>
                                {rialTable.map(item => (
                                    <Row2 theme={theme} width="33%">
                                        <Column>{item.dest}</Column>
                                        <Column>{item.reqTime}</Column>
                                        <Column><Text tid={item.settlement} /></Column>
                                    </Row2>
                                ))}
                                <Row theme={theme} width="33%">
                                    <Column><Text tid="ayande-account" /></Column>
                                    <Column><Text tid="10minsettlement" /></Column>
                                    <Column></Column>
                                </Row>
                            </Table>
                        </TableContainer>
                        <CustomP  style={{ textAlign: "center" }}>
                            زمان بندی تسویه ریالی در روزهای غیرکاری
                        </CustomP>
                        <TableContainer>
                            <Table>
                                <HeaderRow theme={theme} width="33%">
                                    <Column><Text tid="dest-acc" /></Column>
                                    <Column><Text tid="settlement-time" /></Column>
                                    <Column><Text tid="settlement" /></Column>
                                </HeaderRow>
                                {rialTableWeekend.map(item => (
                                    <Row2 theme={theme} width="33%">
                                        <Column>{item.dest}</Column>
                                        <Column>{item.reqTime}</Column>
                                        <Column><Text tid={item.settlement} /></Column>
                                    </Row2>
                                ))}
                                <Row theme={theme} width="33%">
                                    <Column><Text tid="ayande-account" /></Column>
                                    <Column><Text tid="10minsettlement" /></Column>
                                    <Column></Column>
                                </Row>
                            </Table>
                        </TableContainer>
                    </TextContainer>
                </Card>

        </SubPageLayout>
    )
}


const wageData = [
    { wage: "0.003", bound: "-", level: "0star", time: "-" },
    { wage: "0.003", bound: "till100mill", level: "1star", time: "-" },
    { wage: "0.0025", bound: "till300mill", level: "2star", time: "15days" },
    { wage: "0.002", bound: "till750mill", level: "3star", time: "30days" },
    { wage: "0.0015", bound: "morethan750", level: "4star", time: "60days" },
    { wage: "0.001", bound: "morethan750", level: "5star", time: "90days" }
]

const depositeWithdrawDetail = [
    { property: "Bitcoin(segwit)", withdraw: "0.00035", minWithdraw: "0.002", deposite: "0", minDeposite: "0.0004" },
    { property: "Bitcoin(legacy)", withdraw: "0.00035", minWithdraw: "0.002", deposite: "0.0002", minDeposite: "0.0004" },
    { property: "Etherium", withdraw: "flexible", minWithdraw: "0.03", deposite: "0", minDeposite: "0.001" },
    { property: "Lightcoin", withdraw: "0.01", minWithdraw: "0.002", deposite: "0", minDeposite: "0.0005" },
    { property: "Tether(Etherium)", withdraw: "flexible", minWithdraw: "500", deposite: "10", minDeposite: "15" },
    { property: "Tether(Theron)", withdraw: "0.8", minWithdraw: "10", deposite: "0", minDeposite: "0.5" },
    { property: "Ripple", withdraw: "0.1", minWithdraw: "21", deposite: "0", minDeposite: "0" },
    { property: "Bitcoin cache", withdraw: "0.001", minWithdraw: "0.01", deposite: "0", minDeposite: "0.0005" },
    { property: "Binance", withdraw: "0.001", minWithdraw: "0.1", deposite: "0", minDeposite: "0" },
    { property: "EOS", withdraw: "0.1", minWithdraw: "5", deposite: "0", minDeposite: "0" },
    { property: "Estelar", withdraw: "0.01", minWithdraw: "100", deposite: "0", minDeposite: "0" },
    { property: "Etherium classic", withdraw: "0.01", minWithdraw: "1", deposite: "0", minDeposite: "0" },
    { property: "Theron", withdraw: "1", minWithdraw: "100", deposite: "0", minDeposite: "0.001" },
    { property: "Dogecoin", withdraw: "10", minWithdraw: "200", deposite: "0", minDeposite: "5" },
    { property: "Peyman", withdraw: "0.05", minWithdraw: "0.06", deposite: "0", minDeposite: "0" }
]

const rialTable = [
    { dest: "", reqTime: <Text tid="before9:30" />, settlement: "same1045" },
    { dest: <Text tid="not-ayande-account" />, reqTime: "9:50 - 12:50", settlement: "same1345" },
    { dest: "", reqTime: "12:50 - 22:50", settlement: "next345" },
]

const rialTableWeekend = [
    { dest: "", reqTime: <Text tid="before9:50" />, settlement: "same1045" },
    { dest: <Text tid="not-ayande-account" />, reqTime: "9:50 - 12:50", settlement: "same1345" },
    { dest: "", reqTime: <Text tid="thurs-fri" />, settlement: "next345" },
]



const HeaderRow = styled.div`
    display: flex;
    & > div {
      width: ${props => props.width};
    };
    background-color: ${props => (props.theme === "dark" && "#0E1013") || "#E7EAF1"};
    padding: 20px;
    border-radius: 10px;
    font-size: 14px;

    @media screen and (max-width: 960px) {
        font-size: 11px;
        padding: 10px;
    }
  
  @media screen and (max-width: 768px){
    width: fit-content;
  }
`

const Table = styled.div`
    width: 80%;

    @media screen and (max-width: 960px) {
        width: 95%;
        //margin-right: 90px;
    }
`

const Card = styled.div`
    width: 95%;
    border-radius: 20px;
    z-index: 10;
    background-color: ${props => (props.theme === "dark" && "#070a10") || "white"};
    align-self: center;
    margin-top: ${props => props.marginTop};
    box-shadow: 0 5px ${props => (props.theme === "dark" && "black") || "white"};
  
  @media screen and (max-width: 1050px) {
    border-radius: 8px;
  }
`

const Row = styled.div`
    display: flex;
    padding: 20px;

  & > div {
    width: ${props => props.width};
  };
  
    background-color: ${props => (props.theme === "dark" && "#070a10") || "#ffffff"};
    &:nth-child(even){
        background-color: ${props => (props.theme === "dark" && "#16191E") || "#E7EAF1"};
    }
    border-radius: 10px;
    font-size: 14px;

    @media screen and (max-width: 1050px) {
        font-size: 11px;
        padding: 10px;
    }
  @media screen and (max-width: 768px){
    width: fit-content;
  }
`

const Row2 = styled.div`
    padding: 20px;
    background-color: ${props => (props.theme === "dark" && "#070a10") || "#ffffff"};
    border-radius: 10px;

  & > div {
    width: ${props => props.width};
  };
    font-size: 14px;
  display: flex;

    @media screen and (max-width: 1050px) {
        font-size: 11px;
        padding: 10px;
    }

  @media screen and (max-width: 768px){
    width: fit-content;
  }
`

const Column = styled.div`
    padding: 20px;
    text-align: center;
    min-width: 120px;
  font-size: 0.8rem;

  &:first-child{
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    &:last-child{
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }

    @media screen and (max-width: 1050px) {
        padding: 10px;
    }
`

const TableContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 60px;

    @media screen and (max-width: 1050px) {
        overflow-x: auto;
    }
`

const FastScrollContainer = styled.div`
    width: ${props => props.width};
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 40px;
    position: absolute;
    left: ${props => props.left && "10px"};
    right: ${props => props.right && "10px"};
    top: 0;
    z-index: 20;

    @media screen and (max-width: 1050px) {
        width: 200px;
        top: 0;
    }
`

const FastScrollItem = styled.div`
    font-size: 14px;
    color: ${props => props.color};
    text-align: ${props => props.textAlign};
    border-bottom: 1px dotted ${props => props.color};
    padding: 3px;
    transition: all 0.3s;
    cursor: pointer;
    &:hover{
        transform: translateY(-3px);
    }

    @media screen and (max-width: 1050px) {
        font-size: 11px;
    }
`


export default Fees;
