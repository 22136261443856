import { forwardRef, useRef, useState } from 'react'
import { FaCaretDown, FaCaretUp } from 'react-icons/fa'
import { CgMenuLeftAlt } from 'react-icons/cg'
import { useMainContext } from '../../contexts/main'
import { useWindowSize } from '../../hooks/useWindowSize'
import useClickOutside from '../../hooks/useClickOutside'
import { useNavigate } from 'react-router-dom'
import {
	Image,
	Body,
	DropBody,
	DropItem,
	Profile,
	Notification,
	Avatar,
	MText,
	MLink,
	Button,
	UnreadNotificationIcon,
	Menu,
	LinkRow,
} from '../../styles/layout/HeaderStyles'
import { MOBILE_SIZE, TABLET_SIZE } from '../../utils/constants'
import Text from '../../utils/Text'
import { ProfileDropdown } from '../dropdowns/ProfileDropdown'
import { useProfileQuery } from '../../services/react-query/useProfileQuery'
import { BsMoonFill } from 'react-icons/bs'
import { RiSunFill } from 'react-icons/ri'
import { MdSettings, MdSpaceDashboard } from 'react-icons/md'
import { BsGrid3X3GapFill, BsHash } from 'react-icons/bs'
import { ImPriceTags } from 'react-icons/im'
import { FaEquals } from 'react-icons/fa'
import { SiMarketo, SiHashnode } from 'react-icons/si'
import { useUnreadNotificationQuery } from '../../services/react-query/useNotificationsQuery'
import NotificationDropdown from '../dropdowns/NotificationDropdown'
import { useLogout } from '../../services/react-query/useProfileQuery'
import SettingsDropdown from '../dropdowns/SettingsDropdown'
import { HOME } from '../../utils/constants'
import { AnimatePresence } from 'framer-motion'
import { Flex } from '../../styles/common/CommonStyles'
import styled, { css } from 'styled-components'
import logo from '../../assets/images/logo.svg'

const MasterHeader = () => {
	const {
		main: { lang, setLang, theme, setTheme },
		profile: { token },
	} = useMainContext()
	const { width } = useWindowSize()
	const navigate = useNavigate()

	const [langOpen, setLangOpen] = useState(false)
	const [profileOpen, setProfileOpen] = useState(false)
	const [settingsOpen, setSettingsOpen] = useState(false)
	const [notificationsOpen, setNotificationsOpen] = useState(false)
	const [menuIsOpen, setIsMenuOpen] = useState(false)
	const [extraLinkOpen, setExtraLinkOpen] = useState(false)

	const extraLinkRef = useRef()
	const outRef = useRef()
	const onOutsideClicked = () => {
		setLangOpen(false)
		setProfileOpen(false)
		setNotificationsOpen(false)
		setSettingsOpen(false)
		setIsMenuOpen(false)
	}
	useClickOutside(outRef, onOutsideClicked)
	useClickOutside(extraLinkRef, () => setExtraLinkOpen(false))

	const { data: profile } = useProfileQuery()
	const { data: notifications } = useUnreadNotificationQuery(1)
	const { mutate: logout } = useLogout()

	const onThemeChange = () => {
		setTheme(theme === 'dark' ? 'light' : 'dark')
	}

	const onOptionClicked = (type) => {
		switch (type) {
			case 'english':
				setLangOpen(false)
				setLang('en')
				break
			case 'فارسی':
				setLangOpen(false)
				setLang('fa')
				break
			default:
				break
		}
	}

	const onProfileItemsClicked = (type, path = null) => {
		if (type === 'exit') {
			logout()
		} else {
			window.location.href = HOME + `user/${path}`
		}
	}

	const goToSignin = (type) => {
		window.location.href = HOME + `user/register-signin?id=${type}`
	}

	const onLinkClicked = (path) => {
		if (path.includes(HOME)) {
			window.location.href = path
		} else {
			navigate(path)
		}
	}

	const respMenuClicked = () => {
		setIsMenuOpen((state) => !state)
	}

	const onGridMenuClicked = () => {
		setExtraLinkOpen(true)
	}

	return (
		<Body resp={width < TABLET_SIZE}>
			<AnimatePresence exitBeforeEnter>
				{menuIsOpen && (
					<Menu variants={menuVariants} animate='in' exit='out' initial='out' ref={outRef}>
						{links.map((link) => (
							<LinkRow justify='flex-start' onClick={() => onLinkClicked(link.path)}>
								{link.icon}
								<MLink key={link.title}>
									<a href={link.path}>
										<Text tid={link.title} />
									</a>
								</MLink>
							</LinkRow>
						))}
					</Menu>
				)}
			</AnimatePresence>
			<Flex>
				<img
					src={logo}
					style={{
						height: width < TABLET_SIZE ? '30px' : '40px',
						cursor: 'pointer',
					}}
					alt=' '
					onClick={() => onLinkClicked('/')}
				/>
				{width > TABLET_SIZE &&
					links.map((link) => (
						<MLink key={link.title}>
							<a href={link.path} target={link.title === 'coin-360' && 'blank'}>
								<Text tid={link.title} />
							</a>
						</MLink>
					))}
			</Flex>

			<Flex>
				{token ? (
					<Flex style={{ margin: '0 5px', position: 'relative' }}>
						<Notification
							onClick={() => setNotificationsOpen(true)}
							size={width < TABLET_SIZE ? 20 : 25}
						/>
						{notifications?.data?.length ? <UnreadNotificationIcon /> : null}
					</Flex>
				) : null}
				{notificationsOpen && <NotificationDropdown ref={outRef} notifications={notifications} />}
				{width > MOBILE_SIZE && (
					<Flex onClick={onGridMenuClicked} style={{ margin: '0 15px', cursor: 'pointer' }}>
						<BsGrid3X3GapFill size={22} color={theme === 'dark' ? 'white' : 'black'} />
						{extraLinkOpen && <LinkDropdown options={extraLinks} ref={extraLinkRef} />}
					</Flex>
				)}
				{width > TABLET_SIZE ? (
					<>
						<Flex onClick={onThemeChange} style={{ margin: '0 15px', cursor: 'pointer' }}>
							{theme === 'dark' ? (
								<RiSunFill color='#ffc800' size={25} />
							) : (
								<BsMoonFill color='#c3c5b7' size={25} />
							)}
						</Flex>
						<Flex style={{ margin: '0 15px' }}>
							{langOpen ? (
								<FaCaretUp color='#c3c5b7' size={18} />
							) : (
								<FaCaretDown color='#c3c5b7' size={18} />
							)}
							<MText onClick={() => setLangOpen((state) => !state)}>{languages[lang]}</MText>
							{langOpen && (
								<Dropdown
									ref={outRef}
									options={languageOptions}
									onOptionClicked={onOptionClicked}
									active={lang === 'en' ? 'english' : 'فارسی'}
								/>
							)}
						</Flex>
					</>
				) : (
					<Flex style={{ margin: '0 5px' }}>
						<MdSettings onClick={() => setSettingsOpen(true)} size={20} color='#c3c5b7' />
						{settingsOpen && <SettingsDropdown ref={outRef} />}
					</Flex>
				)}
				{token ? (
					<Profile
						onClick={() => setProfileOpen((state) => !state)}
						size={width < TABLET_SIZE ? '34px' : '42px'}
					>
						{profile?.avatar ? (
							<Image src={profile?.avatar} alt=' ' size={width < TABLET_SIZE ? '34px' : '42px'} />
						) : (
							<Avatar size={width < TABLET_SIZE ? 18 : 22} />
						)}
						{profileOpen && (
							<ProfileDropdown
								ref={outRef}
								width={width}
								profile={profile}
								onProfileItemsClicked={onProfileItemsClicked}
							/>
						)}
					</Profile>
				) : (
					<>
						<MLink onClick={() => goToSignin('signin')}>
							<Text tid='signin' />
						</MLink>
						<Button onClick={() => goToSignin('register')}>
							<Text tid='register' />
						</Button>
					</>
				)}
				{width < TABLET_SIZE && (
					<CgMenuLeftAlt onClick={respMenuClicked} size={28} color='#c3c5b7' />
				)}
			</Flex>
		</Body>
	)
}

const Dropdown = forwardRef((props, ref) => {
	const { options, active, onOptionClicked } = props

	return (
		<DropBody ref={ref}>
			{options.map((option) => (
				<DropItem key={option} onClick={() => onOptionClicked(option)} active={active === option}>
					<Flex style={{ padding: '0 20px' }}>{option}</Flex>
				</DropItem>
			))}
		</DropBody>
	)
})

const LinkDropdown = forwardRef((props, ref) => {
	const { options } = props

	const navigate = useNavigate()
	const navigateToPath = (path) => {
		navigate(path)
	}

	return (
		<DropBody ref={ref} bg={'tInputBg'} minWidth='200px'>
			{options?.map((option, idx) => (
				<LinkItem
					key={option}
					last={idx + 1 === options.length}
					onClick={() => navigateToPath(option.path)}
				>
					<Text tid={option.title} />
				</LinkItem>
			))}
		</DropBody>
	)
})

const extraLinks = [
	{ title: 'ecosystem', path: '/ecosystem' },
	{ title: 'mobile-application', path: '/apps' },
	{ title: 'rayaexopedia', path: '/rayaexopedia' },
	{ title: 'academy', path: '/academy' },
	{ title: 'services', path: '/services' },
	{ title: 'support', path: '/support' },
]

const LinkItem = styled.div`
	padding: 10px 16px;
	color: ${(props) => props.theme.color};
	${(props) =>
		!props.last &&
		css`
			border-bottom: 1px solid ${(props) => props.theme.color}15;
		`};
	background-color: ${(props) => props.theme.tInputBg};
	min-width: 140px;

	&:hover {
		background-color: ${(props) => props.theme.mainBg};
		border-radius: 16px;
	}

	@media screen and (max-width: 768px) {
		font-size: 0.7rem;
	}
`

const links = [
	{ title: 'main', path: '/', icon: <FaEquals size={18} /> },
	{ title: 'dashboard', path: HOME + 'user/dashboard', icon: <MdSpaceDashboard size={18} /> },
	{ title: 'rayaex-trade', path: HOME + 'trade', icon: <SiMarketo size={18} /> },
	{ title: 'price-list', path: HOME + 'trade/markets', icon: <ImPriceTags size={18} /> },
	{ title: 'fees', path: '/fees', icon: <SiHashnode size={18} /> },
	{ title: 'coin-360', path: 'https://coin360.com/', icon: <BsHash size={18} /> },
	{ title: 'rayaex-help', path: '/help-center', icon: <SiMarketo size={18} /> },
]

const languages = {
	en: 'english',
	fa: 'فارسی',
}

const languageOptions = ['english', 'فارسی']

const menuVariants = {
	in: {
		x: 0,
		transition: { duration: 0.4 },
	},
	out: {
		x: -300,
		transition: { duration: 0.4 },
	},
}

export default MasterHeader
