import SubPageLayout from '../../components/layouts/SubPageLayout'
import { ContentBody, ContentHeadLine } from '../../styles/components/SupPagesStyles'
import { useTranslation } from 'react-i18next'

export default function Academy() {
	const { t } = useTranslation()

	const content = [
		{ type: 'headline', value: 'academy-section-1-head' },
		{ type: 'body', value: 'academy-section-1-body' },
	]
	return (
		<SubPageLayout header='academy'>
			{content.map((item, index) => {
				const { type, value } = item

				const Component = type === 'headline' ? ContentHeadLine : ContentBody

				return <Component key={index}>{t(value)}</Component>
			})}
		</SubPageLayout>
	)
}
