import SubPageLayout from '../../components/layouts/SubPageLayout'
import { ContentBody } from '../../styles/components/SupPagesStyles'


export default function Feedback() {
    return (
        <SubPageLayout header='about-us'>
            <ContentBody>
                رایاایکسچنج برای ارتقای سطح خدمات خود از نظرات، انتقادات و پیشنهادات شما به گرمی استقبال می کند.
            </ContentBody>
        </SubPageLayout>
    )
}
