import i18next from 'i18next'
import { Suspense, useEffect } from 'react'
import { useMainContext } from '../contexts/main'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'
import Landing from './Landing'
import GlobalLoading from '../components/layouts/GlobalLoading'
import { SocketProvider } from '../contexts/socket'
import { SocketContentProvider } from '../contexts/socket-content'
import AboutUs from './subpages/AboutUs'
import RulesPage from './subpages/Rules'
import Jobs from './subpages/Jobs'
import PrivacyAndPolicy from './subpages/Privacy'
import Platform from './subpages/Platform'
import Rayaexopedia from './subpages/DigiAlphaopedia'
import ResearchAndDevelopment from './subpages/r&d'
import Apps from './subpages/apps'
import SeriveP2p from './subpages/SeriveP2p'
import Downloads from './subpages/downloads'
import Support from './subpages/Supprt'
import ContactUs from "./subpages/ContactUs";
import Academy from "./subpages/Academy";
import Fees from "./subpages/Fees";
import Feedback from "./subpages/Feedback";
import HelpCenter from "./subpages/HelpCenter";
import Faq from "./subpages/Faq";
import Ecosystem from "./subpages/Ecosystem";
import Ambassadors from "./subpages/Ambassadors";
import SeriveOtc from "./subpages/SeriveOtc";


const Index = () => {
	const {
		main: { lang },
	} = useMainContext()

	useEffect(() => {
		i18next.changeLanguage(lang)
		//eslint-disable-next-line
	}, [])

	return (
		<Router>
			<Suspense fallback={<GlobalLoading />}>
				<Routes>
					<Route
						index
						path='/'
						element={
							<SocketProvider>
								<SocketContentProvider>
									<Landing />
								</SocketContentProvider>
							</SocketProvider>
						}
					/>

					{/* subpages go here */}
					<Route path='/about-us' element={<AboutUs />} />
					<Route path='/rules' element={<RulesPage />} />
					<Route path='/jobs' element={<Jobs />} />
					<Route path='/privacy' element={<PrivacyAndPolicy />} />
					<Route path='/platform' element={<Platform />} />
					<Route path='/rayaexopedia' element={<Rayaexopedia />} />
					<Route path='/r&d' element={<ResearchAndDevelopment />} />
					<Route path='/apps' element={<Apps />} />
					<Route path='/services-p2p' element={<SeriveP2p />} />
					<Route path='/services-otc' element={<SeriveOtc />} />
					<Route path='/downloads' element={<Downloads />} />
					<Route path='/support' element={<Support />} />
					<Route path='/contact-us' element={<ContactUs />} />
					<Route path='/academy' element={<Academy />} />
					<Route path='/fees' element={<Fees />} />
					<Route path='/feedback' element={<Feedback />} />
					<Route path='/help-center' element={<HelpCenter />} />
					<Route path='/faq' element={<Faq />} />
					<Route path='/ecosystem' element={<Ecosystem />} />
					<Route path='/ambassadors' element={<Ambassadors />} />



					<Route path='*' element={<Navigate to='/' replace />} />
				</Routes>
			</Suspense>
		</Router>
	)
}

export default Index
