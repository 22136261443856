import SubPageLayout from '../../components/layouts/SubPageLayout'
import {BoxContainer, CustomP, DetailsContainer, P, TextContainer} from "../../styles/components/SupPagesStyles";
import {useMainContext} from "../../contexts/main";


export default function ContactUs() {

    return (
        <SubPageLayout header='about-us'>
            <TextContainer width="60%" >
                <P>
                    در صورت داشتن هرگونه سوال و یا درخواست می توانید از طریق مسیرهای ارتباطی زیر با ما در تماس باشید.
                </P>
                <CustomP>
                    ⦁      مسیر اول: پاسخ به پرسشهای پرتکرار
                </CustomP>
                <P>
                    اگر در بخش های مختلف رایاایکسچنج سوالی دارید لطفاً قبل از تماس با پشتیبانی، ابتدا پرسش‌های متداول را بخوانید
                </P>
                <CustomP>
                    ⦁     مسیر دوم: راهنمایی پشتیبانی آنلاین
                </CustomP>
                <P>
                    در هر ساعت شبانه روز از طریق سیستم چت آنلاین (کادرصورتی رنگ در پایین صفحات )
                    با کارشناسان پشتیبانی رایاایکسچنج می‌توانید
                    در ارتباط باشید و پاسخ سوالات خود را دریافت کنید.
                </P>
                <CustomP>
                    ⦁     اطلاع رسانی در شبکه های اجتماعی
                </CustomP>
                <P>
                    برای دریافت آخرین اطلاعیه‌ها و اخبار رایاایکسچنج کانال رسمی اطلاع رسانی رایاایکسچنج د
                    ر تلگرام و اکانت رسمی رایاایکسچنج در اینستاگرام مراجعه کنید.
                </P>
                <CustomP>
                    ⦁     ارسال ایمیل به واحد پشتیانی
                </CustomP>
                <P>
                    سوالات و درخواست های خود را به آدرس ایمیل پشتیبانی support@rayaex.ir ارسال نمایید.
                </P>
                <CustomP>
                    ⦁     سامانه پاسخگویی به سوالات امور مشتریان
                </CustomP>
                <P>
                    کارشناسان رایاایکسچنج پاسخگوی سوالات کاربران در طول روز در ساعات .... خواهند بود.
                </P>
            </TextContainer>
            <BoxContainer>
                <iframe style={{ border: 0 }} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2721.8648068155576!2d51.369502858905136!3d35.77879078091204!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3f8e07e8197af337%3A0xd8d775d8dd594af1!2sSa&#39;adat%20Abad%2C%20District%202%2C%20Tehran%2C%20Tehran%20Province%2C%20Iran!5e0!3m2!1sen!2sca!4v1644320222480!5m2!1sen!2sca" width="100%" height="450" allowfullscreen="" loading="lazy"></iframe>
                <DetailsContainer>
                    <P>تهران-سعادت آباد- بلوار شهرداری - خیابان ۱۱ غربی - پلاک ۳۶ - واحد 4</P>
                    <P>کدپستی: ۱۹۹۸۸۴۳۹۶۵</P>
                    <P>شماره تلفن:‌ ۰۲۱۲۲۱۱۳۵۵۶۲ / ۰۲۱۲۲۱۳۵۵۳۹</P>
                </DetailsContainer>
            </BoxContainer>
        </SubPageLayout>
    )
}
