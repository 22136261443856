import {useNavigate} from "react-router-dom";
import {useMainContext} from "../../contexts/main";
import {normalFetch} from "../fetch-api/get";
import {useMutation, useQuery} from "react-query";
import {deleteApi} from "../fetch-api/delete";


const useProfileQuery = () => {
    const {
        profile: { token, setToken }
    } = useMainContext()
    const navigate = useNavigate()

    return useQuery('fetch-profile', () => normalFetch(token, 'profile'), {
        enabled: !!token,
        select: (res) => res.data.data,
        refetchOnWindowFocus: false,
        staleTime: 30 * 60 * 1000,
        onError: () => {
            setToken(null)
            navigate('/register-signin')
        }
    })
}


const useLogout = () => {
    const {
        profile: { token, setToken },
    } = useMainContext()
    const navigate = useNavigate()
    return useMutation('logout', () => deleteApi(token, 'auth/token'), {
        onSuccess: () => {
            setToken(null)
            navigate('/register-signin')
        },
    })
}


export {
    useProfileQuery,
    useLogout
}