import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { FaCaretUp, FaCaretDown } from "react-icons/fa"
import Text from "../../utils/Text";
import useClickOutside from "../../hooks/useClickOutside";
import { SOCKET_URL } from "../../utils/constants";
import { useMainContext } from "../../contexts/main";
import {DText, Flex, Padding} from "../../styles/common/CommonStyles";
import {formatNumber} from "../../utils/common";


const FilterDropdown = (
    {
        options, defaultOption,
        onOptionChanged, icon,
        activeTab = null, width = null, heigth = null,
        isCoin = false, margin = null, rFontSize = null,
        type = null,
    }
) => {

    const { main: { theme } } = useMainContext()
    const ref = useRef()


    const [open, setOpen] = useState(false)
    const [shownOptions, setShownOptions] = useState(options)
    const [searchValue, setSearchValue] = useState("")
    const [selectedOption, setSelectedOption] = useState(null)
    const [isCoinBorderActive, setIsCoinBorderActive] = useState(false)


    useEffect(() => {
        if (!isCoin) {
            setShownOptions(options)
        }
    }, [options])

    useEffect(() => {
        setSelectedOption(isCoin ? shownOptions[defaultOption] : defaultOption)
    }, [defaultOption])

    useEffect(() => {
        if (activeTab) {
            setSelectedOption(defaultOption)
        }
    }, [activeTab])


    useClickOutside(ref, () => {
        setOpen(false)
        setIsCoinBorderActive(false)
    })

    const changeDropdownStatus = () => {
        setIsCoinBorderActive(true)
        setOpen(state => !state)
    }

    const onOptionClicked = (idx) => {
        onOptionChanged(idx)
        setSelectedOption(isCoin ? shownOptions[idx] : idx)
        setSearchValue("")
        setShownOptions(options)
    }

    const onSearchValueChange = (e) => {
        const val = e?.target?.value
        setSearchValue(val)
        const filtered = options.filter(o => o.coin.includes(val) || o.fa.includes(val) || o.name.toLowerCase().includes(val))
        setShownOptions(filtered)
    }


    return (
        <Body
            ref={ref}
            margin={margin}
            width={width}
            height={heigth}
            isCoin={isCoin}
            active={isCoinBorderActive}
            onClick={changeDropdownStatus}
        >
            {icon &&
                <IconContainer>
                    {icon}
                </IconContainer>
            }
            <Option fontSize={rFontSize}>
                {isCoin ?
                    <CoinOption
                        type={type}
                        option={selectedOption}
                        open={open}
                        searchValue={searchValue}
                        onSearchValueChange={onSearchValueChange}
                    />
                    :
                    <Text
                    tid={typeof selectedOption === "number" ? shownOptions[selectedOption] : selectedOption}
                    />
                }
            </Option>
            <IconContainer>
                {open ?
                    <FaCaretUp size={16} color={theme === "dark" ? "#c3c5b7" : "#191c20"} />
                    :
                    <FaCaretDown size={16} color={theme === "dark" ? "#c3c5b7" : "#191c20"} />
                }
            </IconContainer>
            <AnimatePresence exitBeforeEnter>
                {open &&
                    <DropBody width={width} isCoin={isCoin} variants={variants} initial="out" animate="in" exit="out" ref={ref}>
                        {
                            shownOptions.map((option, idx) => (
                                isCoin ?
                                    <CoinOption type={type} option={option} onClick={() => onOptionClicked(idx)}/>
                                    :
                                <Flex fw key={option} onClick={() => onOptionClicked(idx)}>
                                    <Checkbox on={idx === selectedOption} />
                                    <Option fontSize={rFontSize}>
                                        <Text tid={option} />
                                    </Option>
                                </Flex>
                        ))}
                    </DropBody>
                }
            </AnimatePresence>
        </Body>
    )
}

const CoinOption = (props) => {

    const {
        option, type, open, searchValue, onSearchValueChange
    } = props
    const { main: {lang}} = useMainContext()

    return (
        <>
            {option ?
                open ?
                    <Input
                        value={searchValue}
                        onChange={onSearchValueChange}
                        placeholder={
                            lang === "en" ?
                                "Please choose a coin..."
                                :
                                "لطفا ارز مورد نظر را انتخاب کنید..."
                        }
                        autoFocus
                    />
                    :
                <Flex fw style={{ padding: "10px 15px" }} {...props}>
                    <Flex width="20%">
                        <Img src={SOCKET_URL +
                            `assets/icon/${option.id}.png`} alt=" " />
                    </Flex>
                    <Flex justify="flex-start" width="30%">
                        <DText fontSize="s">{option[lang === "en" ? "name" : "fa"]?.toUpperCase()}</DText>
                    </Flex>
                    <Flex justify="flex-end" width="50%">
                        <DText fontSize="s">{formatNumber(option[type])}</DText>
                    </Flex>
                </Flex>
                :null}
        </>
)
}

const Input = styled.input`
  outline: none;
  width: 100%;
  height: 100%;
  border: none;
  background-color: transparent;
  color: ${props => props.theme.color};
`

const variants = {
    in: {
        opacity: 1, transition: { duration: 0.3 }
    },
    out: {
        opacity: 0, transition: { duration: 0.3 }
    }
}

const Body = styled.div`
    min-width: 160px;
    margin: ${props => props.margin || "0 20px"};
    width: ${props => props.width || "fit-content"};
    height: ${props => props.height || "32px"};
    border-radius: 6px;
    border: 1px solid ${props => props.isCoin ? (props.active ? props.theme.clientMain : "transparent") : "#cfcfcf" };
    background-color: ${props => props.isCoin ? props.theme.otcInput : props.theme.tInputBg};
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    @media screen and (max-width: 1050px){
        height: ${props => props.height || "30px"};
    }
  
    @media screen and (max-width: 480px) {
      margin: 0 4px;
    }
`

const DropBody = styled(motion.div)`
    min-width: 160px;
    position: absolute;
    z-index: 100;
    width: 100%;
    height: fit-content;
    border-radius: 6px;
    background-color: ${props => props.isCoin ? props.theme.otcInput : props.theme.tInputBg};
    top: 120%;
    display: flex;
    flex-direction: column;
`

const IconContainer = styled.div`
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Option = styled.div`
    color: ${props => props.theme.color};
    font-size: 0.8rem;
    width: 64%;
    display: flex;
    justify-content: flex-start;
    margin: 0 12px;
    align-items: center;

    @media screen and (max-width: 768px){
        font-size: ${props => props.fontSize || "0.7rem"};
    }

  @media screen and (max-width: 480px){
    font-size: ${props => props.fontSize || "0.6rem"};
  }
`

const Img = styled.img`
    width: 32px;
    height: 32px;
    margin: 0 20px;

    @media screen and (max-width: 480px){
        width: 22px;
        height: 22px;
    }
`


const Checkbox = styled.div`
    background-color: ${props => (props.on && props.theme.mainGreen) || "white"};
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 0 10px;
`

export default FilterDropdown;