import {useState} from "react";
import {stringToNumber} from "../utils/common";
import {useQueryContext} from "../contexts/query";
import {useBankWithdrawMutation, useCreateOtcOrder, useWithdrawMutation} from "../services/react-query/useOtcQuery";


const useOtcInvoiceHook = (
    data,
    type,
    otcType,
    networks,
    onClose
) => {

    const [networkData, setNetworkData] = useState({
        selected: null, wallet: null, bank: null
    })
    const {mutate: withdrawCoin} = useWithdrawMutation()
    const {mutate: withdrawBank} = useBankWithdrawMutation()
    const { setToast } = useQueryContext()
    const [activeWallet, setActiveWallet] = useState(0)

    const onOtcSuccess = () => {
        onClose()
        if (type === "buy") {
            const net = networks.deposit[networkData.selected]
            const payload = {
                amount: parseFloat(data.amount),
                coin: data.coin,
                wallet: networkData.wallet.id,
                network: net.network
            }
            withdrawCoin(payload)
        }else {
            const payload = {
                account: networkData.bank.id,
                amount: parseFloat(data.amount)
            }
            withdrawBank(payload)
        }
    }
    const {mutate: otcTrade, isLoading: otcLoading } = useCreateOtcOrder(onOtcSuccess)


    const onOtcContinue = () => {
        console.log("onOtcContinue")
        let payload = {
            type, amount: parseFloat(stringToNumber(data.amount)), coin: data.coin, isDirect: activeWallet === 1
        }
        if (otcType === "limit") {
            payload = {...payload, lowerBound: {price: data.lower}, upperBound: {price: data.upper}}
        }
        if (activeWallet === 1) {
            if (type === "buy" && networkData.selected === null) {
                setToast({
                    isError: true, show: true,
                    message: "choose-network-error"
                })
                return
            }else if (!networkData.bank && !networkData.wallet) {
                setToast({
                    isError: true, show: true,
                    message: "choose-account-error"
                })
                return
            }
        }
        otcTrade(payload)
    }


    const networkOperation = (type, data) => {
        switch (type) {
            case "change-network":
                setNetworkData(state => ({...state, selected: data}))
                break
            case "change-bank":
                setNetworkData(state => ({...state, bank: data}))
                break
            case "change-wallet":
                setNetworkData(state => ({...state, wallet: data}))
                break
            default:
                break
        }
    }

    return {
        activeWallet,
        setActiveWallet,
        networkData,
        otcLoading,
        onOtcContinue,
        networkOperation,
    }
}


export {
    useOtcInvoiceHook
}