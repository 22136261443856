import {
	Background,
	CFlex,
	CMargin,
	DLink,
	DText,
	Flex,
	Grid,
	MBtn,
	Padding,
	RMargin,
	QrWrapper,
} from '../../styles/common/CommonStyles'
import Text from '../../utils/Text'
import { FooterWrapper, LineBreak } from '../../styles/layout/MainStyles'
import QRCode from 'react-qr-code'
import { HOME, MOBILE_SIZE } from '../../utils/constants'
import { useWindowSize } from '../../hooks/useWindowSize'
import { useNavigate } from 'react-router-dom'
import { FaFacebook, FaTelegramPlane } from 'react-icons/fa'
import { IoLogoTwitter } from 'react-icons/io'

const MasterFooter = ({ hasQr }) => {
	const { width } = useWindowSize()
	const navigate = useNavigate()

	const goToSignin = (type) => {
		window.location.href = HOME + `user/register-signin?id=${type}`
	}

	return (
		<FooterWrapper>
			<CFlex fw fh>
				{/* first section */}
				<Flex fw>
					<Background bg='primaryBg'>
						<Padding padding='40px 0'>
							<CFlex fw>
								<DText fontSize='sb' primary>
									<Text tid='trade-now' />
								</DText>
								<CMargin margin='15px' />
								<Flex>
									<MBtn onClick={() => goToSignin('register')} active>
										<Text tid='register' />
									</MBtn>
									<RMargin margin='10px' />
									<MBtn onClick={() => goToSignin('signin')}>
										<Text tid='sign-in' />
									</MBtn>
								</Flex>
							</CFlex>
						</Padding>
					</Background>
				</Flex>

				{/* second section */}
				<Flex justify='space-around' fw>
					<Background bg='mainBg'>
						<Padding padding='20px'>
							<Grid gc={4} gcR={2} width='80%'>
								{links.map((section) => (
									<CFlex align='flex-start' justify='flex-start'>
										<CMargin margin='5px' />
										<DText fontSize='b' primary>
											<Text tid={section.header} />
										</DText>
										<CMargin margin='5px' />
										{section.links.map((link) => (
											<>
												<CMargin margin='5px' />
												<DLink fontSize='m' main onClick={() => navigate(link.path)}>
													<Text tid={link.link} />
												</DLink>
											</>
										))}
									</CFlex>
								))}
							</Grid>
							{hasQr ? (
								<Flex fh>
									<QrWrapper>
										<QRCode size={width > MOBILE_SIZE ? 100 : 80} value={HOME} />
									</QrWrapper>
								</Flex>
							) : null}
						</Padding>
					</Background>
				</Flex>

				<CFlex fw>
					<Background bg='mainBg'>
						<Padding padding='20px 40px'>
							<LineBreak />
							<CFlex fw>
								<DText fontSize='m' main>
									<Text tid='copyright' />
								</DText>
								<CMargin margin="10px" />
								<Flex>
									{community.map((com) => (
										<>
											<RMargin margin='10px' />
											<DText cFontSize='24px' style={{ cursor: 'pointer' }} main>
												{com}
											</DText>
										</>
									))}
								</Flex>

							</CFlex>
						</Padding>
					</Background>
				</CFlex>
			</CFlex>
		</FooterWrapper>
	)
}

/**
 * footer links
 * structure => { header, links[ { link, path } ] }
 */
const links = [
	{
		header: 'about-us',
		links: [
			{ link: 'about-us', path: '/about-us' },
			{ link: 'contact-us', path: '/contact-us' },
			{ link: 'rayaex-ecosystem', path: '/ecosystem' },
			{ link: 'jobs-opportunity', path: '/jobs' },
			{ link: 'rules', path: '/rules' },
			// { link: 'privacy-and-policy', path: '/privacy' },
		],
	},

	{
		header: 'products',
		links: [
			{ link: 'platform', path: '/platform' },
			{ link: 'academy', path: '/academy' },
			{ link: 'rayaexopedia', path: '/rayaexopedia' },
			{ link: 'r&d', path: '/r&d' },
			{ link: 'mobile-application', path: '/apps' },
		],
	},
	{
		header: 'services',
		links: [
			{ link: 'p2p-service', path: '/services-p2p' },
			{ link: 'otc-service', path: '/services-otc' },
			{ link: 'downloads', path: '/downloads' },
			{ link: 'fees', path: '/fees' },
			{ link: 'ambassadors', path: '/ambassadors' },
		],
	},
	{
		header: 'support',
		links: [
			{ link: 'support-center', path: '/support' },
			{link: 'feedback', path: '/feedback'},
			{link: 'rayaex-help', path: '/help-center'},
			{ link: 'faq', path: '/faq' },
		],
	},
]

/**
 * community icons goes here
 */
const community = [
	<img src={require("../../assets/images/sochialmedia/1.png")} alt=" " width="20px" />,
	<img src={require("../../assets/images/sochialmedia/2.png")} alt=" " width="20px" />,
	<img src={require("../../assets/images/sochialmedia/3.png")} alt=" " width="20px" />,
	<img src={require("../../assets/images/sochialmedia/4.png")} alt=" " width="20px" />,
	<img src={require("../../assets/images/sochialmedia/5.png")} alt=" " width="20px" />,
]

export default MasterFooter
