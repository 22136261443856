import SubPageLayout from '../../components/layouts/SubPageLayout'
import { useTranslation } from 'react-i18next'
import { ContentBody, ContentHeadLine } from '../../styles/components/SupPagesStyles'

const RulesPage = () => {
	const { t } = useTranslation()

	const content = [
		{ type: 'headline', value: 'rules-section-1-head' },
		{ type: 'body', value: 'rules-section-1-body' },
		{ type: 'headline', value: 'rules-section-2-head' },
		{ type: 'body', value: 'rules-section-2-body' },
	]

	return (
		<SubPageLayout header='rules'>
			{content.map((item, index) => {
				const { type, value } = item

				const Component = type === 'headline' ? ContentHeadLine : ContentBody

				return <Component key={index}>{t(value)}</Component>
			})}
		</SubPageLayout>	)
}

export default RulesPage
