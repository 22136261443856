import MainLayout from './MainLayout'
import { Absolute, DText, Flex, SubCard } from '../../styles/common/CommonStyles'
import Text from '../../utils/Text'
import { SubPageDescription, SubPageHead } from '../../styles/components/SupPagesStyles'
import { useMainContext } from '../../contexts/main'

const SubPageLayout = ({ header = '', description = '', noCard=false, children }) => {
	const {
		main: { lang },
	} = useMainContext()

	return (
		<MainLayout>
			<Flex fw>
				<Absolute top='-80px'>
					<img
						src={require('../../assets/images/banner/subpage.jpg')}
						alt=' '
						width='100%'
						height={450}
						style={{ objectFit: 'cover' }}
					/>
				</Absolute>
				<Absolute
					top='150px'
					right={lang === 'en' ? 0 : `100px`}
					left={lang !== 'en' ? 0 : `100px`}
				>
					<SubPageHead>
						{!!header && (
							<DText fontSize='sb' color='white'>
								<Text tid={header} />
							</DText>
						)}
					</SubPageHead>
					<SubPageDescription>{!!description && <Text tid={description} />}</SubPageDescription>
				</Absolute>
			</Flex>
			<SubCard noCard top='400px'>
				<div>{children}</div>
			</SubCard>
		</MainLayout>
	)
}

export default SubPageLayout
