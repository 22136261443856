import SubPageLayout from '../../components/layouts/SubPageLayout'
import Text from '../../utils/Text'
import { ContentBody } from '../../styles/components/SupPagesStyles'

export default function Jobs() {
	return (
		<SubPageLayout header='jobs-opportunity'>
			<ContentBody>
				<Text tid={'jobs-description'} />
			</ContentBody>
		</SubPageLayout>
	)
}
