import SubPageLayout from '../../components/layouts/SubPageLayout'
import { ContentBody, ContentHeadLine } from '../../styles/components/SupPagesStyles'
import { useTranslation } from 'react-i18next'

export default function SeriveP2p() {
	const { t } = useTranslation()

	const content = [
		{ type: 'headline', value: 'services-section-1-head' },
		{ type: 'body', value: 'services-section-1-body' },
	]

	return (
		<SubPageLayout header='services'>
			{content.map((item, index) => {
				const { type, value } = item

				const Component = type === 'headline' ? ContentHeadLine : ContentBody

				return <Component key={index}>{t(value)}</Component>
			})}
		</SubPageLayout>
	)
}
