import { forwardRef } from 'react'
import { useMainContext } from '../../contexts/main'
import {CFlex, Flex} from '../../styles/common/CommonStyles'
import { DropBody, MText, NotItem, NotText } from '../../styles/layout/HeaderStyles'
import Text from '../../utils/Text'
import { IoIosClose } from 'react-icons/io'
import { formatDate } from '../../utils/common'
import { useNavigate } from 'react-router-dom'
import { useReadNotificationMutation } from '../../services/react-query/useNotificationsQuery'
import { useWindowSize } from '../../hooks/useWindowSize'
import {HOME, TABLET_SIZE} from '../../utils/constants'


const NotificationDropdown = forwardRef((props, ref) => {
	const { notifications } = props
	const {
		main: { lang },
	} = useMainContext()
	const { width } = useWindowSize()

	const { mutate: readNotification } = useReadNotificationMutation()

	const onNotificationRead = (id) => {
		readNotification(id)
	}

	const onSeeAllClick = () => {
		window.location.href = HOME + 'user/notifications'
	}

	return (
		<DropBody
			style={{
				padding: '10px 20px',
				minWidth: width < TABLET_SIZE ? '240px' : '320px',
				backgroundColor: '#1b1e23',
			}}
			ref={ref}
		>
			<Flex
				style={{
					justifyContent: 'space-between',
					borderBottom: '1px solid #c3c5b770',
					padding: '10px 0',
				}}
			>
				<MText>
					<Text tid='my-notifications' />
				</MText>
				<MText onClick={onSeeAllClick} style={{ color: '#ffc800' }}>
					<Text tid='see-all' />
				</MText>
			</Flex>
			{notifications?.data?.map((not) => (
				<NotItem key={not._id}>
					<IoIosClose
						onClick={() => onNotificationRead(not._id)}
						style={{ cursor: 'pointer', margin: '0 4px' }}
						size={22}
					/>
					<CFlex style={{ width: '70%' }}>
						<NotText fontSize='0.8rem'>{not.title}</NotText>
						<Flex>
							<NotText fontSize='0.7rem' style={{ margin: '0 5px' }}>
								{formatDate(not.createdAt, 'date', lang === 'en' ? 'en-US' : 'fa-IR')}
							</NotText>
							<NotText fontSize='0.7rem'>
								{formatDate(not.createdAt, 'time', lang === 'en' ? 'en-US' : 'fa-IR')}
							</NotText>
						</Flex>
					</CFlex>
					<NotText
						fontSize='0.7rem'
						color={not.priority === 'HIGH' ? 'red' : not.priority === 'MEDIUM' ? 'yellow' : 'green'}
					>
						<Text tid={not.priority.toLowerCase()} />
					</NotText>
				</NotItem>
			))}
			{(notifications?.data?.length === 0 || !notifications) && (
				<Flex height="100%">
					<MText fontSize='0.9rem'>
						<Text tid='no-notification' />
					</MText>
				</Flex>
			)}
		</DropBody>
	)
})

export default NotificationDropdown
