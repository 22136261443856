import styled from "styled-components";


const MainBody = styled.div`
	background-color: ${(props) => props.theme.mainBg};
	color: ${(props) => props.theme.color};
	direction: ${(props) => (props.theme.english ? 'ltr' : 'rtl')};
	//font-family: ${(props) => (props.theme.english ? 'Sans' : 'Vazir')};
	letter-spacing: ${(props) => (props.theme.english ? '1.2px' : '1px')};
	height: 100%;
	min-height: 100vh;
	width: 100%;
	overflow: hidden;
    padding-top: 80px;

	@media screen and (max-width: 1050px) {
		padding-bottom: 60px;
	}
`

const FooterWrapper = styled.div`
  direction: ${props => props.theme.english ? "ltr" : "rtl"};
  font-family: ${(props) => (props.theme.english ? 'Roboto' : 'Vazir')};
  
  @media screen and (max-width: 1050px) {
    padding-bottom: 60px;
  }
`

const LineBreak = styled.div`
  width: 100%;
  height: 1px;
  border-radius: 4px;
  background-color: ${props => props.theme.color};
  margin: 10px 0;
`


export {
    MainBody,
    FooterWrapper,
    LineBreak
}