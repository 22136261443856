import MainLayout from '../components/layouts/MainLayout'
import Banner from '../components/main/Banner'
import PriceTable from '../components/main/PriceTable'
import CardsSlider from '../components/main/CardsSlider'
import TradingWidget from '../components/main/TradingWidget'
import Cards from '../components/main/Cards'
import TradeEverywhere from '../components/main/TradeEverywhere'
import MobileMoc from '../components/main/MobileMoc'
import LandingOtc from '../components/main/LandingOtc'

const Landing = () => {
	return (
		<MainLayout>
			<Banner />
			<TradingWidget type={'ticket'} />
			{/*<CardsSlider />*/}
			{/*<TradingWidget />*/}
			<LandingOtc />
			<PriceTable />
			<TradeEverywhere />
			<Cards />
			{/*<MobileMoc />*/}
		</MainLayout>
	)
}

export default Landing
