import { motion } from "framer-motion";
import React, {useCallback, useRef} from "react";
import styled, { ThemeProvider } from "styled-components";
import { GrFormClose } from "react-icons/gr";
import { useContext } from "react";
import { useDropzone } from 'react-dropzone'
import {useMainContext} from "../../contexts/main";
import useClickOutside from "../../hooks/useClickOutside";


const AmbassadorForm = ({ onClose }) => {

    const { main: {theme, lang} } = useMainContext()
    const english = lang === "en"

    const outRef = useRef()
    useClickOutside(outRef, () => onClose())

    const onDrop = useCallback(acceptedFiles => {

    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    return (
        <Wrapper dir={english ? "ltr" : "rtl"}>
            <ThemeProvider theme={FormTheme[theme]}>
                <Body animate="in" exit="out" initial="out" variants={variants} ref={outRef}>
                    <Container>
                        <CloseBtn onClick={onClose} />
                        <Label>
                            نام
                        </Label>
                        <Input

                        />
                        <Label>
                            نام خانوادگی
                        </Label>
                        <Input

                        />
                        <Label>
                            کد ملی
                        </Label>
                        <Input

                        />
                        <Label>
                            تاریخ تولد
                        </Label>
                        <Input

                        />
                        <Label>
                            شماره همراه
                        </Label>
                        <Input

                        />
                        <Label>
                            آدرس حساب شبکه اجتماعی
                        </Label>
                        <Input

                        />
                        <Label fontSize="0.7rem">
                            اینفلوئنسر محترم لطفا خود را معرفی نمایید (تجربه در زمینه رمزارز، ساختن محتوا، نوع شبکه اجتماعی و ....)
                        </Label>
                        <TextArea

                        />
                        <Label fontSize="0.7rem">
                            اگر طرحی برای دعوت از کاربران جدید دارید بیان نمایید.
                        </Label>
                        <TextArea

                        />
                        <Drag {...getRootProps()}>
                            <input {...getInputProps()} />
                            {
                                isDragActive ?
                                    <p>Drop the files here ...</p> :
                                    <Upload>آپلود عکس</Upload>
                            }
                        </Drag>
                        <BtnContainer>
                            <Button onClick={onClose}>ثبت</Button>
                            <Button onClick={onClose}>لغو</Button>
                        </BtnContainer>
                    </Container>
                </Body>
            </ThemeProvider>
        </Wrapper>
    )
}

const FormTheme = {
    light: {
        bg: "white",
        color: "#070a10",
        form: "#f8f8f8",
        primary: "#FF0099"
    },
    dark: {
        bg: "#070a10",
        color: "#ffffff96",
        form: "#25272C",
        primary: "#00FFCC"
    }
}


const variants = {
    in: {
        opacity: 1, transition: { duration: 0.5 }
    },
    out: {
        opacity: 0, transition: { duration: 0.5 }
    }
}



const Wrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    max-height: 100%;
    position: fixed;
    direction: ${props => props.dir};
    left: 0;
    top: 60px;
    backdrop-filter: blur(8px);
    display: flex;
    justify-content: center;
    align-items: center;
`

const Container = styled.div`
    width: 100%;
    min-height: 600px;
  height: calc(100vh - 160px);
    overflow-y: scroll;
    margin: 10px auto 10px auto;
    padding: 40px 60px;
    
    &::-webkit-scrollbar{
        display: none;
    }
`


const Body = styled(motion.div)`
    width: 50%;
    min-width: 400px;
    border-radius: 8px;
    position: relative;
    background-color: ${props => props.theme.bg};
    box-shadow: 0 0 10px black;
    display: flex;
    flex-direction: column;
`


const Input = styled.input`
    width: 100%;
    margin: 0 auto;
    height: 40px;
    border-radius: 4px;
    background-color: ${props => props.theme.form};
    color: ${props => props.theme.color};
    padding: 0 12px;
    border: none;

    &:focus{
        border: 1px solid ${props => props.theme.primary};
    }
`

const TextArea = styled.textarea`
    background-color: ${props => props.theme.form};
    width: 100%;
    color: ${props => props.theme.color};
    border: none;
    padding: 5px 12px;

    &:focus{
        border: 1px solid ${props => props.theme.primary};
    }
`

const Label = styled.div`
    color: ${props => props.theme.color};
    font-size: ${props => props.fontSize || "0.8rem"};
    font-weight: 600;
    letter-spacing: 1.2px;
    margin-top: 15px;
`

const CloseBtn = styled(GrFormClose)`
    color: ${props => props.theme.primary};
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    cursor: pointer;
`

const Drag = styled.div`
    width: 100%;
    margin: 40px auto;
`

const Upload = styled.div`
    margin: 0 auto;
    padding: 8px 16px;
    color: ${props => props.theme.color};
    text-align: center;
    border-radius: 8px;
    border: 1px dotted ${props => props.theme.primary};
`

const BtnContainer = styled.div`
    display: flex;
    margin-top: 60px;
    justify-content: space-between;
    align-items: center;
`

const Button = styled.div`
    padding: 8px 16px;
    color: black;
    background-color: ${props => props.theme.primary};
    border-radius: 8px;
    width: 40%;
    text-align: center;
    cursor: pointer;
`

export default AmbassadorForm