import Text from "../../utils/Text";
import styled from "styled-components"
import { GoSearch } from "react-icons/go";
import SubPageLayout from "../../components/layouts/SubPageLayout";
import {useMainContext} from "../../contexts/main";
import {useNavigate} from "react-router-dom";


function HelpCenter() {

    const { main: {theme, lang} } = useMainContext()
    const english = lang === "en"
    const navigate = useNavigate()

    return (
        <SubPageLayout>
                    <Title theme={theme}>
                        خوش آمدید چه طور می توانم به شما کمک کنم؟
                    </Title>
                    <SearchBar>
                        <SearchContainer fa={!english} background={theme === "dark" ? "#2b3139" : "#f2f3f5"}>

                            <SearchInput
                                fa={!english}
                                placeholder={english ? "Search" : "جستجو"}
                                // value={searchValue}
                                // onChange={onInputValueChange}
                            />
                            <SearchIcon color={theme === "dark" ? "#848e9c" : "#707a8a"} />
                        </SearchContainer>
                    </SearchBar>
                    <Grid>
                        {helpCards.map(card => (
                            <HelpCard
                                theme={theme}
                                onClick={() => navigate(card.route)}
                            >
                                <Header theme={theme}>
                                    <Text tid={card.name} />
                                </Header>
                            </HelpCard>
                        ))}
                    </Grid>
        </SubPageLayout>
    );
}

const helpCards = [
    {name: "start-help", route: "#" },
    {name: "faq", route: "/faq" },
    {name: "support", route: "/support"},
    {name: "api-docs", route: "#" },
    {name: "rayaexopedia", route: "/rayaexopedia" },
    {name: "academy", route: "/academy" },
    {name: "society", route: "#" },
    {name: "ecosystem", route: "/ecosystem" },
]

const Card = styled.div`
    width: 95%;
    border-radius: 20px;
    z-index: 10;
    justify-content: center;
    background-color: ${props => (props.theme === "dark" && "#070a10") || "white"};
    align-self: center;
    margin-top: ${props => props.marginTop};
    box-shadow: 0 0 5px ${props => (props.theme === "dark" && "black") || "white"};
    direction: ${props => props.dir} !important;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
`

const Title = styled.div`
    width: 100%;
    color: ${props => (props.theme === "light" && "#FF0099") || "#00FFCC"} !important;
    text-align: center;
    font-size: 1.3rem;
    margin-top: 50px;
    letter-spacing: 1.5px;
`

const SearchBar = styled.div`
  height: 60px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SearchContainer = styled.div`
  width: 50%;
  height: 70%;
  border-radius: 5px;
  margin-top: 40px;
  display: flex;
  background-color: ${(props) => props.background};
  align-items: center;
  padding: ${(props) => (props.fa && "2px") || "5px"};
`;

const SearchIcon = styled(GoSearch)`
  color: ${(props) => props.color};
  width: 16px;
  height: 16px;
  margin: 0 14px;
`;

const SearchInput = styled.input`
  height: 70%;
  width: 100%;
  background-color: transparent;
  outline: none;
  color: ${(props) => props.theme.color};
  border: none;
  padding: 4px 12px;
  margin-top: ${(props) => props.fa && "4px"};
  font-size: 0.9rem;
`;

const Grid = styled.div`
    margin: 80px 0;
  display: grid;
  grid-template-columns: repeat(4, 280px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 960px) {
    grid-template-columns: repeat(2, 280px);
  }

  @media screen and (max-width: 560px) {
    grid-template-columns: repeat(1, 280px);
  }

`

const HelpCard = styled.div`
  border-radius: 8px;
  height: 180px;
  background-color: ${props => (props.theme === "dark" && "#171b24") || "#f8f8f8"};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: all 0.5s;

  &:hover{
    background-image: linear-gradient(45deg, #FF009999 0%, #00FFCC99 100%);
    transform: scale(1.05);
    }
`

const Header = styled.div`
  font-size: 18px;
  color: ${props => (props.theme === "dark" && "white") || "#171b24"};
  font-weight: 500;
  margin-top: 10px;
  letter-spacing: 1.2px;
`


export default HelpCenter;
